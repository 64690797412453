import React from 'react'
import styled from 'styled-components';
import b4 from '../../images/b4.png'
import Fs from './firstSaction'
import Ss from './secondSection'
import Header from './header'


const Container = styled.div`
height: 100vh;
width: 100vw;
position: relative;
left: 0;
top: 0;
//background-image: url(${b4});
background-size: cover;
display: flex;


@media screen and (max-width: 700px) {
height: 100vh;
}

`
const Wrapper= styled.div`
position: relative;
width: calc(100vw - var(--navBarWidth));
left: calc(var(--navBarWidth));
height: 100%;
padding:  40px 0px;
@media screen and (max-width: 1200px) {
left: 0;
width: 100%;
}
`

const MiddleSection = styled.div`
position: relative;
width: 100%;
height: calc(100% - 200px);
display: flex;
flex:1;
flex-wrap: nowrap;
align-items: center;
justify-content: center;
`
const Index = () => {
  const [changeText,setChangeText]=React.useState(0)
  return (
    <Container id="News">
      <Wrapper>
        <Header />
        <MiddleSection>
          <Fs setChangeText={setChangeText} />
          {<Ss changeText={changeText} />}
        </MiddleSection>
        </Wrapper>
    </Container>
  )
}

export default React.memo(Index)