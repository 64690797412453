import React from 'react'
import styled from 'styled-components';
import b3 from '../../images/b3.png'
import Mobile_tournament from '../../images/Mobile_tournament.png'
import Header from './header'
import MiddleSectionFirstSide from './middleSectionFirstSide'
import MiddleSectionSecondSide from './middleSectionSecondSide'
import Shab from './shab'
import lolskin from '../../images/storeImages/lolskin.webp'
import 'react-alice-carousel/lib/alice-carousel.css';
import { ToastContainer, toast } from 'react-toastify';

const Container = styled.div`
height: 100vh;
width: 100vw;
left: 0;
top: 0;
//background-image: url(${(window.innerWidth<1200)? b3: b3});
background-size: cover;
position: relative;
z-index: 5555555;
background-size: cover;
min-height:800px;

@media screen and (max-width: 1200px) {
height: auto;
padding-bottom: 50px;
}


`

const Wrapper = styled.div`
position: relative;
    left: calc(var(--navBarWidth)  ) ;
    width: calc(100vw - var(--navBarWidth)) ;
    height: 100vh;
    z-index: 156165561;
    @media screen and (max-width: 1200px) {
      left: 0;
      width: 100vw;
      height: auto !important;
    }
;
`

const MiddleSection=styled.div`
width: 100%;
height: calc(100% - 200px);
display: flex;
flex-wrap: nowrap;
@media screen and (max-width: 1200px) {
  left: 0;
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}
`



const LolSkin = styled.div`
position:absolute;
top:0px;
height:100%;
width:100%;
z-index: -1;

img{
position: absolute;
right: 0px;
transform: translateX(40%) translateY(10%);
top: 30%;
height: 70%;
width: auto;
}
@media screen and (max-width: 1200px) {
  display: none;

}
`


const notify = () => toast.info('Coming soon', {
  position: toast.POSITION.BOTTOM_CENTER
});
const startnotify=()=>{
  notify()
}
const index = () => {
  
  return (
    <Container id="Store">
      <ToastContainer   style={{fontSize:"2rem"}}/>
      <LolSkin>
        <img src={lolskin}/>
      </LolSkin>
      <Shab/>
      <Wrapper id="StoreTournament">
          <Header/>
          <MiddleSection id="MiddleSectionStore">
              <MiddleSectionFirstSide/>
              <MiddleSectionSecondSide />
          </MiddleSection>
      </Wrapper>
    </Container>
  )
}

export default index