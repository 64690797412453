import React from 'react'

const Store = (props) => {
  const [hoverOn,sethoverOn]=React.useState(false)
  const changeColorOnHover=(data)=>{
  if(data === 'onMouseEnter'){
    sethoverOn(true)
  }else{
    sethoverOn(false)
  }
  }


  return (
<svg onMouseEnter={()=>{changeColorOnHover("onMouseEnter")} } onMouseLeave={()=>changeColorOnHover("onMouseLeave")} id="StoreI" xmlns="http://www.w3.org/2000/svg" width="40" height="57.166" viewBox="0 0 40 57.166">
  <g  transform="translate(-59 -474.834)">
    <text id="Store-2" data-name="Store" transform="translate(59 527)" fill={(props.select||hoverOn)?"#FB5D38":"#9ca2cf"} font-size="15" font-family="Poppins-Medium, Poppins" font-weight="500"><tspan x="0" y="0">Store</tspan></text>
    <g id="Store_icon" data-name="Store icon" transform="translate(62 474.834)">
      <path id="Tracé_22" data-name="Tracé 22" d="M0,0H32.063V32.063H0Z" fill="none"/>
      <path id="Tracé_23" data-name="Tracé 23" d="M29.055,26.047v2.672H2.336V26.047H3.672V17.019A6.015,6.015,0,0,1,1.846,8.943L5.469,2.668A1.336,1.336,0,0,1,6.626,2H24.766a1.336,1.336,0,0,1,1.157.668l3.61,6.255a6.006,6.006,0,0,1-1.814,8.1v9.028ZM7.4,4.672,4.147,10.3a3.34,3.34,0,0,0,5.966,2.96,1.336,1.336,0,0,1,2.479,0,3.34,3.34,0,0,0,6.2,0,1.336,1.336,0,0,1,2.48,0,3.34,3.34,0,1,0,5.954-2.982l-3.24-5.607H7.4Z" transform="translate(0.336 0.672)" fill={(props.select||hoverOn)?"#FB5D38":"#9ca2cf"}/>
    </g>
  </g>
</svg>

  )
}

export default Store