import React from 'react'
import styled from 'styled-components'
import backArrow from '../../icons/Backarrow.svg'
import { useNavigate  } from "react-router-dom";
import backprivecyandterms from '../../images/backprivecyandterms.png'

const Container = styled.div`
position: relative;
width: 100%;
height: auto;
background-image: url(${backprivecyandterms});
background-size: cover;
padding-bottom: 30px;

`
const Header = styled.div`
position: relative;
height: auto;
width: 90%;
left: 50%;
transform: translateX(-50%);
padding: 30px;
box-sizing: border-box;
cursor: pointer;

`
const TextContainer = styled.div`
position: relative;
height: auto;
width: 90%;
left: 50%;
transform: translateX(-50%);
padding: 30px;
box-sizing: border-box;
h1{
  font-size: 2rem;
  color: white;
}
h2{
  font-size: 1.7rem;
  color: #fe4b57;
}
p{
  font-size: 1.5rem;
    line-height: 1.5;
  color: white;
  color: #a0a5c7;
}
ul,li{
  font-size: 1.3rem;
    line-height: 1.6;
  color: white;
    color: #a0a5c7;

}
&:after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 30px;
  inset: 0px;
    mix-blend-mode: overlay;

 background-color: #FFFFFF ;
 opacity: 0.05;
   background-image: linear-gradient(to bottom, #fff 0%, #181b31 215%);

}
`
const Index = () => {

    const navigate = useNavigate();
    const goTo = (route) => {
            navigate("/")
  }
  React.useEffect(() => {
    document.querySelector("#root").scrollTop=0
  },[])

  return (
    <Container>
      <Header>
        <img alt="ArrowRight" onClick={() => goTo('Termsandconditions')} src={backArrow}/>
      </Header>
      <TextContainer>
        <h1>PRIVACY NOTICE</h1>
        <p style={{marginTop:"30px"}}>
          Thank you for choosing to be part of our community at T7D GAMING Hereinafter referred
          to as (&quot;Company&quot;, &quot;we&quot;, &quot;us&quot;, &quot;our&quot;). We are committed to protecting your personal
          information and your right to privacy. If you have any questions or concerns about this
          privacy notice, or our practices with regards to your personal information, please contact us
          at Dpo@galactechstudio.com.
          When you use any of our services (the &quot;Services&quot;, which include the Vouchers selling
          and the Esports tournament participation), we appreciate that you are trusting us with your
          personal information and take your privacy very seriously. In this privacy notice, we seek to
          explain to you in the clearest way possible what information we collect, how we use it and what
          rights you have in relation to it. We hope you take some time to read through it carefully, as it is
          important. If there are any terms in this privacy notice that you do not agree with, please
          discontinue use of our Services immediately.

          This privacy notice applies to all information collected through our Services (which, as
          described above, includes the vuchers selling and the participation in esports tournaments
          ), as well as, any related services, sales, marketing or events.

          Please read this privacy notice carefully as it will help you understand what we do
          with the information that we collect.
        </p>
        <h2 style={{marginTop:"30px"}}>TABLE OF CONTENTS</h2>
        
        <ul style={{marginTop:"20px"}}>
          <li>1. WHAT INFORMATION DO WE COLLECT?</li>
          <li>2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</li>
          <li>3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
          <li>4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</li>
          <li>5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</li>
          <li>6. HOW LONG DO WE KEEP YOUR INFORMATION?</li>
          <li>7. DO WE COLLECT INFORMATION FROM MINORS?</li>
          <li>8. WHAT ARE YOUR PRIVACY RIGHTS?</li>
          <li>9. CONTROLS FOR DO-NOT-TRACK FEATURES</li>
          <li>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
          <li>11. DO WE MAKE UPDATES TO THIS NOTICE?</li>
          <li>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
          <li>13. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</li>
        </ul>

        <h2 style={{marginTop:"30px"}}>1. WHAT INFORMATION DO WE COLLECT?</h2>

        <p style={{marginTop:"20px"}}>In Short: We collect personal information that you provide to us.</p>

        <p>
          We collect personal information that you voluntarily provide to us when you register to the
          T7D GAMING App by filling the registration form while creating an account, We cite:
        </p>

        <ul>
          <li>1. Username</li>
          <li>2. Username in Games</li>
          <li>3. Country of origin</li>
          <li>4. Phone number</li>
          <li>5. Date of birth (To guarantee that you meet the minimum age required for each Game to participate in a tournament)</li>
          <li>6. Email address,</li>
          <li>7. Gender (Optional)</li>
        </ul>

        <p>
          The information listed above are essential to provide you with the services that our platform
          is offering .
          The personal information that we collect depends also on the context of your interactions
          with us and the choices you make and the products and features you use, so they may T
          include the following:
        </p>

        <ul>
          <li>
            1. We collect Debit/Credit card information (last 4digits/expiry date/name/address) to
            provide you with the possibility of purchasing our in Game currencies offered on our
            online store .
            We also collect and save previous purchases and previous wallet recharges to keep
            track of the transactions and for refund purposes when its needed .
          </li>
          <li>
            2. We collect Data related to your previous tournament participation and history Quests
            and Achievement so you can benefit from our merit system ( GD : Galactech Dust) and
            deliver prize pools when it’s the case .
          </li>
          <li>
            3. We also invite to you fill an Optional Survey related to preferred games/categories to
            optimize our offers and services and provide you with best Gamer experience on our
            platform .
          </li>
          <li>
            4. Social Media Login Data. We may provide you with the option to register with us
            using your existing social media account details, like your Facebook, Twitter or other
            social media account. If you choose to register in this way, we will collect the
            information described in the section called &quot;HOW DO WE HANDLE YOUR SOCIAL
            LOGINS?&quot; below.
          </li>
        </ul>

        <p>
          All personal information that you provide to us must be true, complete and accurate, and
          you must notify us of any changes to such personal information.
        </p>

        <p>Information automatically collected</p>

        <p>
          In Short: Some information — such as your Internet Protocol (IP) address and/or browser
          and device characteristics — is collected automatically when you visit our Website .

          We automatically collect certain information when you visit, use or navigate on our Website
          . This information does not reveal your specific identity (like your name or contact
          information) but may include device and usage information, such as your IP address,
          browser and device characteristics, operating system, language preferences, referring
          URLs, device name, country, location, information about how and when you use our
          Website and other technical information. This information is primarily needed to maintain
          the security and operation of our Website , and for our internal analytics and reporting
          purposes.
        </p>
        
        <h2 style={{marginTop:"30px"}}>2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h2>

        <p style={{marginTop:"20px"}}>
          In Short: We only share information with your consent, to comply with laws, to provide you
          with services, to protect your rights, or to fulfill business obligations.

          We may process or share your data that we hold based on the following legal basis:
          More specifically, we may need to process your data or share your personal information in
          the following situations:
        </p>
        <ul>
            ■ Business Transfers. We may share or transfer your information in connection with,
            or during negotiations of, any merger, sale of company assets, financing, or
          acquisition of all or a portion of our business to another company.
          <br/>
            ■ Affiliates. We may share your information with our affiliates, in which case we will
            require those affiliates to honor this privacy notice. Affiliates include our parent
            company and any subsidiaries, joint venture partners or other companies that we
          control or that are under common control with us.
          <br/>
            ■ Business Partners. We may share your information with our business partners to
            offer you certain products, services or promotions like payments methods offered on
          our platforms .
          <br/>
            ■ Other Users. When you share personal information or otherwise interact with public
            areas of the , such personal information may be viewed by all users and may be
            publicly made available outside the in perpetuity. If you interact with other users of
            our platform and register for our platform through a social network (such as
            Facebook), your contacts on the social network will see your name, profile photo,
            and descriptions of your activity. Similarly, other users will be able to view
            descriptions of your activity, communicate with you within our , and view your profile.
        </ul>

        <h2 style={{marginTop:"30px"}}>3. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>

        <p style={{marginTop:"20px"}}>
          In Short: If you choose to register or log in to our services using a social media account, we
          may have access to certain information about you.

          Our offers you the ability to register and login using your third-party social media account
          details (like your Facebook or Twitter logins). Where you choose to do this, we will receive
          certain profile information about you from your social media provider. The profile
          information we receive may vary depending on the social media provider concerned, but will
          often include your name, email address, friends list, profile picture as well as other
          information you choose to make public on such social media platform.

          We will use the information we receive only for the purposes that are described in this
          privacy notice or that are otherwise made clear to you on the relevant . Please note that we
          do not control, and are not responsible for, other uses of your personal information by your
          third-party social media provider. We recommend that you review their privacy notice to
          understand how they collect, use and share your personal information, and how you can set
          your privacy preferences on their sites and apps.
        </p>

        <h2 style={{marginTop:"30px"}}>4. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h2>

        <p style={{marginTop:"20px"}}>
          In Short: We may transfer, store, and process your information in countries other than your
          own.

          We use AWS servers that are located in UAE and Bahrain . If you are accessing our platform
          from outside, please be aware that your information may be transferred to, stored, and
          processed by us in our facilities and by those third parties with whom we may share your
          personal information (see &quot;WILL YOUR INFORMATION BE SHARED WITH ANYONE?&quot; above),
          in and other countries.
        </p>

        <h2 style={{marginTop:"30px"}}>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
        <p style={{marginTop:"20px"}}>
          In Short: We keep your information for as long as necessary to fulfill the purposes outlined in
          this privacy notice unless otherwise required by law.

          We will only keep your personal information for as long as it is necessary for the purposes
          set out in this privacy notice, unless a longer retention period is required or permitted by law
          (such as tax, accounting or other legal requirements). No purpose in this notice will require
          us keeping your personal information for longer than 3 months.

          When we have no ongoing legitimate business need to process your personal information,
          we will either delete or anonymize such information, or, if this is not possible (for example,
          because your personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any further processing until
          deletion is possible.
        </p>
        <h2 style={{marginTop:"30px"}}>6. DO WE COLLECT INFORMATION FROM MINORS?</h2>

        <p style={{marginTop:"20px"}}>
          In Short: We do not knowingly collect data from or market to children under 13 years of age.

          We do not knowingly solicit data from or market to children under 13 years of age. By using
          our Website , you represent that you are at least 13 or that you are the parent or guardian of
          a minor of less than 13 years of age and consent to such minor dependent’s use of the
          website . If we learn that personal information from users less than 13 years of age has
          been collected, we will deactivate the account and take reasonable measures to promptly
          delete such data from our records. If you become aware of any data we may have collected
          from children under the age of 13, please contact us at dpo@galactechstudio.com.
        </p>

        <h2 style={{marginTop:"30px"}}>7. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
        <p style={{marginTop:"20px"}}>In Short: You may review, change, or terminate your account at any time.</p>
        <span>Account Information</span>
        <p>
          If you would at any time like to review or change the information in your account or
          terminate your account, you can:

          Upon your request to terminate your account, we will deactivate or delete your account and
          information from our active databases. However, we may retain some information in our
          files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our
          Terms of Use and/or comply with applicable legal requirements.

          Opting out of email marketing: You can unsubscribe from our Newsletter email list at any
          time by clicking on the unsubscribe link in the emails that we send or by contacting us using
          the details provided below. You will then be removed from the Newsletter email list —
          however, we may still communicate with you, for example to send you service-related
          emails that are necessary for the administration and use of your account, to respond to
          service requests, or for other non-marketing purposes.
        </p>
        <h2 style={{marginTop:"30px"}}>8. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
        <p style={{marginTop:"20px"}}>
          Most web browsers and some mobile operating systems and mobile applications include a
          Do-Not-Track (&quot;DNT&quot;) feature or setting you can activate to signal your privacy preference
          not to have data about your online browsing activities monitored and collected. At this
          stage no uniform technology standard for recognizing and implementing DNT signals has
          been finalized. As such, we do not currently respond to DNT browser signals or any other
          mechanism that automatically communicates your choice not to be tracked online. If a
          standard for online tracking is adopted that we must follow in the future, we will inform you
          about that practice in a revised version of this privacy notice.
        </p>

        <h2 style={{marginTop:"30px"}}>9. DO WE MAKE UPDATES TO THIS NOTICE?</h2>

        <p style={{marginTop:"20px"}}>
          In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.

          We may update this privacy notice from time to time. The updated version will be indicated
          by an updated &quot;Revised&quot; date and the updated version will be effective as soon as it is
          accessible. If we make material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending you a notification. We

          encourage you to review this privacy notice frequently to be informed of how we are
          protecting your information.
        </p>

        <h2 style={{marginTop:"30px"}}>10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>

        <p style={{marginTop:"20px"}}>
          If you have questions or comments about this notice, you may email us at
          dpo@galactechstudio.com
        </p>

        <h2 style={{marginTop:"30px"}}>11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>

        <p style={{marginTop:"20px"}}>
          Based on the applicable laws of your country, you may have the right to request access to
          the personal information we collect from you, change that information, or delete it in some
          circumstances. To request to review, update, or delete your personal information, please
          submit a request via email on Dpo@galactechstudio.com
        </p>
      </TextContainer>
    </Container>
  )
}

export default Index