import React from 'react'
import styled from 'styled-components'
import ProC from '../../images/Pro.png'
import PremiumC from '../../images/Premium.png'
import FreeC from '../../images/Free.png'
import TournamnetPhoneALL  from '../../images/tournamentWinnersImage/phoneTornament.png'
import ArrowRight  from '../../icons/Arrow_right.png'
import phonetwoTournament  from '../../images/tournamentWinnersImage/phonetwoTournament.png'


import proCard_1 from '../../images/proCard_1.png'
import proCard_2 from '../../images/proCard_2.png'
import proCard_3 from '../../images/proCard_3.png'
 


const Container = styled.div`
position: relative;
width: 50%;
height: 100%;
@media screen and (max-width: 1200px) {
height: auto;
width: 100vw;
display: flex;
flex-direction: column;
gap: 40px;
}
`

const MiddleSectionSecondSide = () => {
    const [currentPage,setCurrentPage]=React.useState(undefined)
    const pages = ["free",'pro','premium']
    const setNextPage=(current)=>{
        if(current === 'free'){
            setCurrentPage('pro')
        }else if(current === 'pro'){
            setCurrentPage('premium')
        }else{
            setCurrentPage('free')
        }
    }
    let count = 1
   /* React.useEffect(()=>{
        setInterval(() => {
            if(count === 1){
                setCurrentPage('free')
                count++
            }else if (count === 2){
                setCurrentPage('pro')
                count++
            }else{
                setCurrentPage('premium')
                count=1
            }
        }, 8000);
    },[])*/
    if(window.innerWidth<1200){
        return(
            <Container>
            <Free setNextPage={setNextPage}/>
            <Pro setNextPage={setNextPage}/>
            <Premuim setNextPage={setNextPage}/>
        </Container>
        )
    }else{
        return (
            <Container>
                {currentPage=== undefined&&<AllData setCurrentPage={setCurrentPage} />}
                {currentPage=== 'free' &&<Free setNextPage={setNextPage}/>}
                {currentPage=== 'pro' &&<Pro setNextPage={setNextPage}/>}
                {currentPage=== 'premium' &&<Premuim setNextPage={setNextPage}/>}
            </Container>
          )
    }

}

export default MiddleSectionSecondSide


/**************************************/
/****************General***************/
/**************************************/
const TwoSection = styled.div`
display: flex;
flex-wrap: nowrap;
@media screen and (max-width: 1200px) {
position: relative;
}
`
const ImagesContainer=styled.div`
position: relative;
min-width: 50%;
max-width: 50%;
height: auto !important;
display: flex;
align-items: center;
justify-content: center;
        @media screen and (min-width: 1200px) and (max-width:1600px)  {
   img{
    max-height: 500px !important;
    width: auto;
   }

}
img{
    width: auto;
    max-height: 700px;
}

@media screen and (max-width: 1200px) {

height: 60vh !important;
left: 0;
position: relative;
img{
    position: absolute;
    height: 60vh !important;
    width: auto;
    transform: translateY(10%);
    left:${(props) => props.left};
    top: 0 !important;
}
}

@media screen and (max-width: 500px){

height: 40vh !important;
left: 0;
position: relative;
img{
    position: absolute;
    height: 40vh !important;
    width: auto;
    transform: translateY(10%);
    left:${(props) => props.left};
    top: 0 !important;
}
}
`

    



const FirstHeadRContainer = styled.div`
    height: 150px;
`

const ButtonsContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
gap: 20px;
`

const Button = styled.div`
    cursor: pointer;
    z-index: 156165;
    transition: 0.3s;
        @media screen and (min-width: 1200px) and (max-width:1600px)  {
        img{
            max-width: 90%;
        }
    }
    &:hover{
        transition: 0.4s;
        transform: scale(1.05);
        filter: drop-shadow(0 0 1.1rem ${(props) => props.color}) drop-shadow(0 0 0.75rem ${(props) => props.color}) 
       
    }
`


const HeaderAndSwipe=styled.div`
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    #slidecursor{
    @media screen and (max-width: 1200px) {
        display: none;
         
        }
    }

`


const DescriptionAndSwipe=styled.div`
display: flex;
align-items: flex-start;
justify-content: flex-start;
flex-direction: column;
z-index: 16556;
min-width: 50%;
max-width: 50%;
p{
    color: white;
    font-size: 1.5rem;
}
@media screen and (max-width: 1200px) {
position: absolute;
right: -10%;
min-width: 60%;
max-width: 60%;
top: 20%;
p{
    font-size: 1.3rem !important;
}
}
`
const Description=styled.div`

@media screen and (max-width: 1200px) {
    p{
    color: white;
    font-size: 1.5rem;
    max-width: 80%;
}
}
`


/**************************************/
/**************************************/
/**************************************/

/*******************************************/
const AllDataContainer  = styled.div`
position: relative;
width: 100%;
height: 100%;
@media screen and (min-width: 1200px) {
    //transform:translateX(120%) ;

    animation-duration: 8s;
//animation-name: showData;
transition-timing-function: cubic-bezier(0, 1.13, 1,-0.13);

@keyframes showData  {

  25%{
    transform:translateX(0%) ;

  }
  75%{
    transform:translateX(0%) ;

  }
  100%{
    transform:translateX(120%) ;

  }
 
}
}

@media screen and (max-width: 1200px) {
height: auto;
position: relative;
padding-top: 30px;
box-sizing: border-box;
width: 100vw;

}
@media screen and (max-width: 500px) {
padding-top: 0px;

}

`

const AllData = (props) => {
        const [firstClick, setFirstClick] = React.useState(false)
    
    return <AllDataContainer id="AllDataContainer">
        <TwoSection id="TwoSection">
            <ImagesContainer>
                <img src={TournamnetPhoneALL}/>
            </ImagesContainer>
            <FirstHeadRContainer>
                <ButtonsContainer>
                    <Button onClick={() => { props.setCurrentPage("free"); setFirstClick(true)}}  className={firstClick == false ? "animatedBgBUttonFree" :""}  color={"#B369F2"}><img alt="FreeC" src={FreeC}/></Button>
                    <Button onClick={()=>{props.setCurrentPage("pro")}} color={"#125FA3"}><img alt="ProC" src={ProC}/></Button>
                    <Button onClick={()=>{props.setCurrentPage("premium")}} color={"#282E5E"}><img alt="PremiumC" src={PremiumC}/></Button>
                </ButtonsContainer>
            </FirstHeadRContainer>
        </TwoSection>
    </AllDataContainer>
}
/*******************************************/

const Free = (props)=>{

    return <AllDataContainer id="AllDataContainer">
        <TwoSection id="TwoSection">
            <ImagesContainer left={"-20%"} id="ImagesContainerfree">
                <img alt="tournamentImage" src={TournamnetPhoneALL}/>
            </ImagesContainer>
            <DescriptionAndSwipe>
             <HeaderAndSwipe>
                    <Button  color={"#B369F2"}><img src={FreeC}/></Button>
                    <img alt="Free"  id="slidecursor" style={{cursor: 'pointer'}} onClick={()=>{props.setNextPage("free")}} src={ArrowRight}/>

             </HeaderAndSwipe>
             <Description>
                <p>
                Playing has never been more fun! 
                Register now and play your favorite games for free and win interesting prizes.

                </p>
             </Description>
             </DescriptionAndSwipe>
        </TwoSection>
    </AllDataContainer>
}
/*******************************************/
const ImagesProContainer=styled.div`
height: 100%;
display: flex;
flex-direction: column;
width: 100%;
position: relative;
display: flex;
align-items: center;
justify-content: center;
width: 50%;
img{
    width: 75%;
}
@media screen and (max-width: 1200px) {
left: 50% !important;
transform: translateX(-50%);
width: 90vw;
margin-top: 10%;
}

`


const Pro = (props)=>{
    return <AllDataContainer >
    <TwoSection  id="TwoSectionTournament">
        <ImagesProContainer id="TwoSectionTournamentImagePro">
            <img alt="proCard1" src={proCard_1}/>
            {window.innerWidth>1200&&<img src={proCard_2}/>}
            {window.innerWidth>1200&&<img src={proCard_3}/>}
        </ImagesProContainer>
        <DescriptionAndSwipe id="TwoSectionDescriptionAndSwipePro">
         <HeaderAndSwipe>
                <Button  color={"#B369F2"}><img alt="Proc" src={ProC}/></Button>
                <img  alt="slideRightCurssor" id="slidecursor" style={{cursor: 'pointer'}} onClick={()=>{props.setNextPage("pro")}} src={ArrowRight}/>

         </HeaderAndSwipe>
         <Description>
            <p>
            Upgrade your gaming experience and participate in our professional tournaments for a chance to compete with the best players in the MENA region.  
            </p>
         </Description>
         </DescriptionAndSwipe>
    </TwoSection>
</AllDataContainer>
}
/*******************************************/

const Premuim = (props)=>{
    return <AllDataContainer>
    <TwoSection>

        <ImagesContainer>
            <img src={phonetwoTournament}/>
        </ImagesContainer>
        <DescriptionAndSwipe>
         <HeaderAndSwipe>
                <Button  color={"#B369F2"}><img alt="premLogo" src={PremiumC}/></Button>
                <img alt="sliderArrowRight" id="slidecursor" style={{cursor: 'pointer'}} onClick={()=>{props.setNextPage("premium")}} src={ArrowRight}/>

         </HeaderAndSwipe>
         <Description>
            <p>
            Going premium is going professional! 
            Create your own permanent pro team to compete in our Premium-exclusive tournaments and make money while playing your favorite games
            </p>
         </Description>
         </DescriptionAndSwipe>
    </TwoSection>
</AllDataContainer>
}

