import React from 'react'
import light from '../../images/light.png'
import styled from 'styled-components';
import { motion } from "framer-motion";


const Light = styled.div`
    right: calc(var(--navBarWidth));
    top: 0px;
    position: absolute;
    z-index: 1561656;
    height: 100vh;
    width: 700px;
    background-image: url(${light});
    background-size: cover;
    background-position: top;
    z-index: 1561651;
    opacity: 0;
`

const showLight={
  opacity: 1,
  scale: 1,
}




const LightC = (props) => {
  const [startAnimation,setStartAnimation]=React.useState(false)
  const pageHeaderPosetion=React.useRef()

  React.useEffect(()=>{
    let divTopPosetionInDocument=pageHeaderPosetion.current.offsetTop+document.getElementById('Home').offsetHeight
    let divheight=pageHeaderPosetion.current.offsetHeight
    let clinetHeight=props.scrolloffsetHeight
    console.log(props.scrollPosetion+clinetHeight,(divTopPosetionInDocument+(divheight/2)))
    if(props.scrollPosetion+clinetHeight>divTopPosetionInDocument+(divheight/2) ){
        setStartAnimation(true)
    }else if(props.scrollPosetion+clinetHeight<divTopPosetionInDocument+(divheight/2) ){
        setStartAnimation(false)
    }
   },[props])

  return (
      <Light style={{zIndex:14514651465165}} ref={pageHeaderPosetion} as={motion.div} animate={startAnimation?showLight:{}}   transition={{  duration: 1.8 ,delay:0.5}}>    
      </Light>
  )
}

export default LightC