import React from 'react'
import styled from 'styled-components'
import volorant from '../../images/tournamentWinnersImage/volorant.png'

const Image = styled.div`
position: absolute;
right: 0;
bottom: 0;
img{
    position: relative;
    width: 30vw;
    max-height: 550px;
}
`

const rightSideCornerImage = () => {
  return (
    <Image>
        <img  alt="vqlo Logo" src={volorant}/>
    </Image>
  )
}

export default rightSideCornerImage