import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    position: relative;
    height: 26vh;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    @media screen and (max-width: 1200px) {
    width: 80%;
    }
    h2{
        text-align: center;
        color: white;
        font-size: 2.3rem;
    }
    p{
        text-align: center;
        color: #9ca2cf;
        font-size: 1.5rem;

    }

`
const Header = () => {
  return (
    <Container>
        <h2>Who we Are</h2>
        <p>
        T7D Gaming, an app created and overseen by T7D, is dedicated to serving the gaming community. Our team, comprised of avid gamers and esports enthusiasts, is committed to providing a comprehensive platform that fulfills all aspects of a gamer's needs and desires. Our goal is to not only centralize gaming resources but also to facilitate the personal and professional development of every player.
        </p>
    </Container>
  )
}

export default Header