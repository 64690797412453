import axios from 'axios'
import React from 'react'
import styled from 'styled-components'
import { useNavigate, useSearchParams, useParams } from "react-router-dom";


const Container = styled.div`
position: relative;
  background-image: url('../src/images/backprivecyandterms.png');
    height: 100vh;
    max-height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
`
const PopUp = styled.div`
background-color: rgba(9,6,11,0.4);
width: 90%;
height: 200px;
max-width: 600px;
border-radius: 15px;
position: relative;
display: flex;
align-items: center;
justify-content: center;
h1{
    color: white;
    font-size: 2rem;
}
`

const Index = () => {
    const [loading, setLoading] = React.useState(true)
    const [message, setMessage] = React.useState("your email has been verified")
    let { id } = useParams();

    React.useEffect(() => {
        axios.get("https://staging.profile.t7d.io/profile/activateEmail", { headers: { authorization: id } }).then(() => {
            setLoading(false)
        }).catch(error => {
            setMessage(error.message)
            setLoading(false)
        })
    },[])
    if (loading === true) {
        return (
            <Container>
                <div class="loader"></div>
            </Container>
        )
    }
    return (
        <Container>
            <PopUp>
                <h1>{message}</h1>
            </PopUp>
        </Container>
    )
}

export default Index
