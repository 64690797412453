import React from 'react'

const Appstore = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="App_store" data-name="App store" width="152.595" height="46.406" viewBox="0 0 152.595 46.406">
  <g id="Groupe_52" data-name="Groupe 52" transform="translate(0 0)">
    <g id="Groupe_51" data-name="Groupe 51">
      <g id="Groupe_50" data-name="Groupe 50">
        <path id="Tracé_52" data-name="Tracé 52" d="M2314.7,1058.34a4.857,4.857,0,0,1,4.851,4.852V1097.8a4.858,4.858,0,0,1-4.851,4.852H2173.9a4.857,4.857,0,0,1-4.851-4.852v-34.609a4.856,4.856,0,0,1,4.851-4.852h140.8m0-1.047H2173.9a5.9,5.9,0,0,0-5.9,5.9V1097.8a5.9,5.9,0,0,0,5.9,5.9h140.8a5.9,5.9,0,0,0,5.9-5.9v-34.609a5.9,5.9,0,0,0-5.9-5.9Z" transform="translate(-2168.002 -1057.293)" fill="#e8e8e8"/>
      </g>
    </g>
  </g>
  <g id="Groupe_55" data-name="Groupe 55" transform="translate(14.832 8.197)">
    <g id="Groupe_53" data-name="Groupe 53" transform="translate(0 6.943)">
      <path id="Tracé_53" data-name="Tracé 53" d="M2310.953,1205.776c-2.476,4.3-.9,10.814,1.836,14.767,1.366,1.977,2.75,3.75,4.644,3.75l.108,0a5.715,5.715,0,0,0,2.156-.575,5.907,5.907,0,0,1,2.553-.6,5.551,5.551,0,0,1,2.431.585,5.144,5.144,0,0,0,2.329.558c2.135-.04,3.445-1.954,4.6-3.643a16.1,16.1,0,0,0,2.016-4.129l.009-.026a.241.241,0,0,0-.128-.294l-.016-.007a6.116,6.116,0,0,1-3.708-5.6,6.3,6.3,0,0,1,2.976-5.264l.023-.015a.24.24,0,0,0,.069-.337,6.925,6.925,0,0,0-5.448-2.957c-.155-.016-.315-.023-.475-.023a9.492,9.492,0,0,0-3.418.854,5.491,5.491,0,0,1-1.638.489,5.352,5.352,0,0,1-1.693-.495,8.218,8.218,0,0,0-2.986-.759h-.075A7.318,7.318,0,0,0,2310.953,1205.776Z" transform="translate(-2309.718 -1201.955)" fill="#e8e8e8"/>
    </g>
    <g id="Groupe_54" data-name="Groupe 54" transform="translate(11.648)">
      <path id="Tracé_54" data-name="Tracé 54" d="M2427.051,1135.617a6.69,6.69,0,0,0-4.414,2.264,6.268,6.268,0,0,0-1.585,4.644.241.241,0,0,0,.219.208q.154.012.311.012a5.656,5.656,0,0,0,4.166-2.137,6.611,6.611,0,0,0,1.552-4.778A.242.242,0,0,0,2427.051,1135.617Z" transform="translate(-2421.014 -1135.617)" fill="#e8e8e8"/>
    </g>
  </g>
  <g id="Groupe_58" data-name="Groupe 58" transform="translate(47.809 21.656)">
    <g id="Groupe_57" data-name="Groupe 57" transform="translate(0 0)">
      <path id="Tracé_68" data-name="Tracé 68" d="M2634.6,1275.426h-6.2l-1.223,2.868h-2.372l5.576-12.393h2.266l5.6,12.393h-2.409Zm-.761-1.806-2.338-5.417-2.319,5.417Z" transform="translate(-2624.806 -1265.724)" fill="#e8e8e8"/>
      <path id="Tracé_69" data-name="Tracé 69" d="M2773.928,1293.567a4.348,4.348,0,0,1,1.709,1.7,5.5,5.5,0,0,1,0,5.072,4.327,4.327,0,0,1-1.709,1.709,5.021,5.021,0,0,1-2.47.6,3.817,3.817,0,0,1-3.027-1.275v4.586h-2.213v-12.888h2.107v1.239a3.416,3.416,0,0,1,1.354-1.009,4.57,4.57,0,0,1,1.778-.336A5.02,5.02,0,0,1,2773.928,1293.567Zm-.708,6.373a3.306,3.306,0,0,0,0-4.284,2.676,2.676,0,0,0-2.01-.814,2.85,2.85,0,0,0-1.434.363,2.616,2.616,0,0,0-1.009,1.036,3.448,3.448,0,0,0,0,3.116,2.612,2.612,0,0,0,1.009,1.035,2.846,2.846,0,0,0,1.434.363A2.673,2.673,0,0,0,2773.22,1299.94Z" transform="translate(-2751.417 -1289.956)" fill="#e8e8e8"/>
      <path id="Tracé_70" data-name="Tracé 70" d="M2889.964,1293.567a4.345,4.345,0,0,1,1.709,1.7,5.5,5.5,0,0,1,0,5.072,4.323,4.323,0,0,1-1.709,1.709,5.015,5.015,0,0,1-2.469.6,3.814,3.814,0,0,1-3.027-1.275v4.586h-2.213v-12.888h2.107v1.239a3.423,3.423,0,0,1,1.353-1.009,4.583,4.583,0,0,1,1.78-.336A5.014,5.014,0,0,1,2889.964,1293.567Zm-.707,6.373a3.306,3.306,0,0,0,0-4.284,2.678,2.678,0,0,0-2.01-.814,2.857,2.857,0,0,0-1.435.363,2.627,2.627,0,0,0-1.009,1.036,3.452,3.452,0,0,0,0,3.116,2.622,2.622,0,0,0,1.009,1.035,2.853,2.853,0,0,0,1.435.363A2.675,2.675,0,0,0,2889.257,1299.94Z" transform="translate(-2855.31 -1289.956)" fill="#e8e8e8"/>
      <path id="Tracé_71" data-name="Tracé 71" d="M3039.143,1276.549a5.761,5.761,0,0,1-2.115-1.08l.8-1.788a6.283,6.283,0,0,0,1.868.983,6.792,6.792,0,0,0,2.222.381,3.836,3.836,0,0,0,2.062-.443,1.338,1.338,0,0,0,.681-1.168,1.131,1.131,0,0,0-.38-.877,2.67,2.67,0,0,0-.965-.54q-.585-.194-1.594-.442a18.6,18.6,0,0,1-2.293-.673,3.776,3.776,0,0,1-1.5-1.054,2.839,2.839,0,0,1-.629-1.938,3.31,3.31,0,0,1,.558-1.868,3.782,3.782,0,0,1,1.682-1.337,6.819,6.819,0,0,1,2.753-.5,8.915,8.915,0,0,1,2.23.283,6.184,6.184,0,0,1,1.894.814l-.726,1.788a7.173,7.173,0,0,0-1.7-.726,6.384,6.384,0,0,0-1.717-.247,3.589,3.589,0,0,0-2.027.46,1.421,1.421,0,0,0-.664,1.222,1.1,1.1,0,0,0,.381.867,2.746,2.746,0,0,0,.965.531q.583.2,1.593.443a16.714,16.714,0,0,1,2.265.664,3.873,3.873,0,0,1,1.514,1.053,2.777,2.777,0,0,1,.629,1.912,3.263,3.263,0,0,1-.558,1.859,3.793,3.793,0,0,1-1.69,1.328,6.89,6.89,0,0,1-2.761.495A9.448,9.448,0,0,1,3039.143,1276.549Z" transform="translate(-2993.884 -1264.209)" fill="#e8e8e8"/>
      <path id="Tracé_72" data-name="Tracé 72" d="M3146.3,1285.05a2.613,2.613,0,0,1-.948.478,4.281,4.281,0,0,1-1.177.159,3.338,3.338,0,0,1-2.408-.814,3.13,3.13,0,0,1-.851-2.373v-4.55h-1.557v-1.77h1.557v-2.16h2.213v2.16h2.532v1.77h-2.532v4.5a1.5,1.5,0,0,0,.337,1.053,1.263,1.263,0,0,0,.973.363,1.949,1.949,0,0,0,1.24-.389Z" transform="translate(-3085.51 -1272.993)" fill="#e8e8e8"/>
      <path id="Tracé_73" data-name="Tracé 73" d="M3215.052,1302.029a4.522,4.522,0,0,1-1.8-1.726,5.163,5.163,0,0,1,0-5,4.541,4.541,0,0,1,1.8-1.718,5.738,5.738,0,0,1,5.187,0,4.543,4.543,0,0,1,1.8,1.718,5.169,5.169,0,0,1,0,5,4.523,4.523,0,0,1-1.8,1.726,5.735,5.735,0,0,1-5.187,0Zm4.6-2.089a3.283,3.283,0,0,0,0-4.284,2.707,2.707,0,0,0-2.019-.814,2.677,2.677,0,0,0-2.01.814,3.3,3.3,0,0,0,0,4.284,2.674,2.674,0,0,0,2.01.814A2.7,2.7,0,0,0,3219.656,1299.94Z" transform="translate(-3151.088 -1289.956)" fill="#e8e8e8"/>
      <path id="Tracé_74" data-name="Tracé 74" d="M3334.625,1292.965v2.107a2.811,2.811,0,0,0-.513-.053,2.671,2.671,0,0,0-2.018.752,3,3,0,0,0-.727,2.169v4.585h-2.213v-9.453h2.106v1.381A3.689,3.689,0,0,1,3334.625,1292.965Z" transform="translate(-3255.437 -1289.956)" fill="#e8e8e8"/>
      <path id="Tracé_75" data-name="Tracé 75" d="M3400.7,1298.506h-7.419a2.57,2.57,0,0,0,1.019,1.655,3.338,3.338,0,0,0,2.044.611,3.443,3.443,0,0,0,2.566-1.027l1.186,1.363a3.988,3.988,0,0,1-1.61,1.151,6.378,6.378,0,0,1-4.939-.23,4.466,4.466,0,0,1-1.833-1.726,4.87,4.87,0,0,1-.646-2.5,4.945,4.945,0,0,1,.628-2.488,4.464,4.464,0,0,1,1.744-1.726,5.084,5.084,0,0,1,2.513-.62,4.965,4.965,0,0,1,2.47.611,4.326,4.326,0,0,1,1.7,1.718,5.2,5.2,0,0,1,.612,2.558C3400.733,1298,3400.72,1298.223,3400.7,1298.506Zm-6.542-3.142a2.644,2.644,0,0,0-.894,1.673h5.365a2.586,2.586,0,0,0-2.674-2.3A2.67,2.67,0,0,0,3394.155,1295.364Z" transform="translate(-3310.868 -1289.956)" fill="#e8e8e8"/>
    </g>
  </g>
  <text id="Download_on_the" data-name="Download on the" transform="translate(46.412 14.219)" fill="#e8e8e8" font-size="8" font-family="CenturyGothic, Century Gothic"><tspan x="0" y="0">Download on the</tspan></text>
</svg>
  )
}

export default Appstore