import React from 'react'
import styled from 'styled-components';
import lol_skin from '../../images/lol_skin.png'
import PUBG from '../../images/PUBG.png'
import news from '../../images/news.png'


const Container = styled.div`
position: relative;
display: flex;
gap: 40px;
width: 50%;
overflow-x: visible;
transition: order 1s;
opacity: 0;
@media screen and (min-width: 1200px) {
    transform: translateX(30%);
}
@media screen and (max-width: 1200px) {
    width: 100vw;
    height: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    position: relative;
    display: none !important;

}

`

const Card = styled.div`
    height: 370px;
    max-height: 370px;
    min-width: 270px;
    position: relative;
    border-radius: 15px;
    cursor: pointer;
    overflow: visible;
    transition:  0.5s;

    &:after{
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background: ${(props) => props.color};
        border-radius: 15px;
        background: linear-gradient(0deg, ${(props) => props.color  } 0%, rgba(255,255,255,0) 100%);
        z-index: 1;
    }
    img{
        position: absolute;
        max-height: 80%;
        left: -15%;
        top: -20%;
        z-index: -1;
    }
    @media screen and (max-width: 1200px) {
    height: 330px;
    max-height: 330px;
    min-width: 230px;
    }
    @media screen and (max-width: 500px )  and (max-height: 670px ){
        img{
            display: none;
        }
        height: 120px;
        max-height: 120px;
        min-width: 150px;

        #BottomSectionHomePage{
            height: 100% !important;
            p{
                font-size: 1rem;

            }
            h2{
                font-size: 2rem;
            }
        }
    }
    @media screen and (max-width: 500px) {
        height: 280px;
        max-height: 280px;
        min-width: 200px;
        #BottomSectionHomePage{
            
            p{
                font-size: 1rem;

            }
            h2{
                font-size: 2rem;
            }
        }
    }
 

`
const BottomSection=styled.div`
height: 35%;
position: absolute;
bottom: 0px;
width: 100%;
z-index: 15616511611641544515;
display: flex;
flex-direction: column;
gap: 20px;
p{
    color: white;
    font-size: 1.4rem;
    text-align: center;
    padding: 0px 10px;
    margin: 0;
    
}
h2{
    color: white;
    font-size: 1.8rem;
    margin: 0;
}

@media screen and (max-width: 1200px) {
    p{font-size: clamp(0.5rem, -1.0rem + 4vw, 1.5rem);}    
    h2{font-size: clamp(0.5rem, -1.0rem + 4vw, 1.5rem);}    

}

`
const LogoAndLink=styled.div`
position: relative;
display: flex;
align-items: center;
justify-content: center;
gap: 10px;

img{
    position: relative;
    left: 0;
    top: 0;
}
`

const SlideBar=styled.div`
width: 100px;
display: flex;
align-items: center;
justify-content: center;
flex-wrap: nowrap;
gap: 5px;
position: absolute;
bottom: -30px;
left: 0px;
@media screen and (max-width: 1200px) {
    left: 50% !important;
    transform: translateX(-50%) !important;
}
`
const Line = styled.div`
width: 15px;
height: 5px;
border-radius:1566165px;
background-color: black ;
cursor: pointer;
transition: 0.5s;

`
const Index = () => {


    React.useEffect(()=>{
        let offsetWidth = document.getElementById('cardOne').offsetWidth  
        let spacing =( ((document.getElementById('cardTwo').offsetLeft - document.getElementById('cardOne').offsetLeft))  - offsetWidth)
        console.log(spacing)
        let cardOne = document.getElementById('cardOne')
        let cardTwo = document.getElementById('cardTwo')
        let cardThree = document.getElementById('cardThree')
        let count = 1

        setInterval(()=>{
            if(count === 1){
                cardOne.style.transform  = `translateX(${((offsetWidth * 2) + (spacing * 2))}px)`
                cardTwo.style.transform  = `translateX(-${ offsetWidth  + (spacing )}px)`
                cardThree.style.transform  = `translateX(-${offsetWidth  + (spacing )}px)`
                count = count + 1
            }else if ( count === 2 ){
                cardOne.style.transform  = `translateX(${offsetWidth + spacing}px)`
                cardTwo.style.transform  = `translateX(${offsetWidth + spacing}px)`
                cardThree.style.transform  = `translateX(-${(offsetWidth * 2)  + (spacing * 2)}px)`
                count = count + 1
            }else{
                cardOne.style.transform  = `translateX(${0}px)`
                cardTwo.style.transform  = `translateX(${0}px)`
                cardThree.style.transform  = `translateX(${0}px)`
                count = 1
            }
        },2500)
    },[])

       
  return (
    <Container id="cardsContainer">
        <Card id="cardOne"  color={"#3F4996"} >
        <img alt="newLogo" style={{top:0,left:0,minHeight:"100%",width:"100%"}} src={news}/>
        <BottomSection id="BottomSectionHomePage">
        <LogoAndLink id="LogoAndLinkContainer">
            <News/>
            <h2>News</h2>
        </LogoAndLink>
        <p>Check ot the latest gaming news and updates!  </p>

        </BottomSection>
        </Card>
        <Card id="cardTwo"  color={"#B42E3F"} >
        <img alt="PubgLogo" src={PUBG}/>
        <BottomSection id="BottomSectionHomePage">
        <LogoAndLink id="LogoAndLinkContainer">
            <Store/>
            <h2>Store</h2>
        </LogoAndLink>
        <p>Make instant and secure purchases now with T7D store </p>

        </BottomSection>
        </Card>
        <Card id="cardThree"  color={"#3F4996"} >
        <img alt="cardThreeLogo" style={{left: "-30%"}} src={lol_skin}/> 
        <BottomSection id="BottomSectionHomePage">
        <LogoAndLink id="LogoAndLinkContainer">
            <Tournaments/>
            <h2>Tournaments</h2>
        </LogoAndLink>
        <p>Make instant and secure purchases now with T7D store  </p>

        </BottomSection>
        </Card>
{/*    <SlideBar>
    <Line />
    <Line />
    <Line />
  </SlideBar>*/}
    </Container>
  )
}

export default Index




const News = ()=>{
    return <svg xmlns="http://www.w3.org/2000/svg" width="24.166" height="24.166" viewBox="0 0 28.166 28.166">
    <g id="News_icon" data-name="News icon" transform="translate(0.001 0)">
      <path id="Tracé_2593" data-name="Tracé 2593" d="M337.4,212.343v3.535a3.477,3.477,0,0,0,6.954,0v-3.535a3.477,3.477,0,0,0-6.954,0Zm0,0" transform="translate(-318.838 -197.377)" fill="white"/>
      <path id="Tracé_2594" data-name="Tracé 2594" d="M300.631,305.266a.825.825,0,0,0-.825.825v.884a4.478,4.478,0,1,1-8.956,0v-.884a.825.825,0,1,0-1.65,0v.884a6.125,6.125,0,0,0,5.3,6.073v1.94h-1.827a.825.825,0,1,0,0,1.65h5.3a.825.825,0,1,0,0-1.65h-1.826v-1.94a6.126,6.126,0,0,0,5.3-6.073v-.884A.825.825,0,0,0,300.631,305.266Zm0,0" transform="translate(-273.291 -288.473)" fill="white"/>
      <path id="Tracé_2595" data-name="Tracé 2595" d="M16.734,15.143c.059,0,.118,0,.176.007v-.183A5.126,5.126,0,0,1,26.4,12.273V4.8a4.805,4.805,0,0,0-4.8-4.8H4.8A4.805,4.805,0,0,0,0,4.8v19a.825.825,0,0,0,1.353.634l5.074-4.228h8.021a7.794,7.794,0,0,1-.19-1.709v-.884a2.478,2.478,0,0,1,2.475-2.476ZM5.244,5.3H21.153a.825.825,0,1,1,0,1.65H5.244a.825.825,0,0,1,0-1.65Zm7.13,10.489H5.244a.825.825,0,0,1,0-1.65h7.13a.825.825,0,1,1,0,1.65Zm-7.13-4.419a.825.825,0,0,1,0-1.65H15.85a.825.825,0,0,1,0,1.65Zm0,0" transform="translate(0 0)" fill="white"/>
    </g>
  </svg>
  
}
const Tournaments = ()=>{
    return <svg xmlns="http://www.w3.org/2000/svg" width="27.562" height="27.176" viewBox="0 0 31.562 30.176">
    <g id="trophy_icon" data-name="trophy icon" transform="translate(0)">
      <path id="Tracé_2580" data-name="Tracé 2580" d="M95.33,0H73.909V2.058h2.425V12.3c0,5.256,3.662,9.583,8.168,9.656,4.63.075,8.4-4.28,8.4-9.662V2.058H95.33Z" transform="translate(-68.839)" fill="white"/>
      <path id="Tracé_2581" data-name="Tracé 2581" d="M130.118,351.236a2.058,2.058,0,0,0-2.058-2.058h-1.613v-4.94a11.7,11.7,0,0,1-3.018.393h-.165a11.6,11.6,0,0,1-2.853-.394v4.942H118.8a2.058,2.058,0,0,0-2.058,2.058v2.451h13.38Z" transform="translate(-107.647 -323.511)" fill="white"/>
      <path id="Tracé_2582" data-name="Tracé 2582" d="M406.663,60v2.058h3.783v4.63a5.153,5.153,0,0,1-4.346,5.083q-.157.488-.358.965a11.717,11.717,0,0,1-.566,1.155h.125a7.211,7.211,0,0,0,7.2-7.2V60Z" transform="translate(-380.942 -55.884)" fill="white"/>
      <path id="Tracé_2583" data-name="Tracé 2583" d="M6.41,71.772a5.153,5.153,0,0,1-4.352-5.084v-4.63H5.841V60H0v6.689a7.211,7.211,0,0,0,7.2,7.2H7.34a11.771,11.771,0,0,1-.587-1.2Q6.561,72.239,6.41,71.772Z" transform="translate(0 -55.884)" fill="white"/>
    </g>
  </svg>
  
  
}
const Store = ()=>{
    return <svg id="Store_icon" data-name="Store icon" xmlns="http://www.w3.org/2000/svg" width="28.063" height="28.063" viewBox="0 0 32.063 32.063">
    <path id="Tracé_22" data-name="Tracé 22" d="M0,0H32.063V32.063H0Z" fill="none"/>
    <path id="Tracé_23" data-name="Tracé 23" d="M29.055,26.047v2.672H2.336V26.047H3.672V17.019A6.015,6.015,0,0,1,1.846,8.943L5.469,2.668A1.336,1.336,0,0,1,6.626,2H24.766a1.336,1.336,0,0,1,1.157.668l3.61,6.255a6.006,6.006,0,0,1-1.814,8.1v9.028ZM7.4,4.672,4.147,10.3a3.34,3.34,0,0,0,5.966,2.96,1.336,1.336,0,0,1,2.479,0,3.34,3.34,0,0,0,6.2,0,1.336,1.336,0,0,1,2.48,0,3.34,3.34,0,1,0,5.954-2.982l-3.24-5.607H7.4Z" transform="translate(0.336 0.672)" fill="white"/>
  </svg>
  
  
}