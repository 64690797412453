import React from 'react'
import styled from 'styled-components'
import CP from '../../images/storeImages/CP.png'
import Diamonds from '../../images/storeImages/Diamonds.png'
import LOL from '../../images/storeImages/LOL.png'
import original from '../../images/storeImages/original.png'
import PS from '../../images/storeImages/PS.png'
import sg from '../../images/storeImages/sg.png'
import UC from '../../images/storeImages/UC.png'
import Steam from '../../images/storeImages/Steam.png'
import { motion } from "framer-motion";


export const hatsArr = [
    {img:CP,name:"CP"},
    {img:Diamonds,name:"free fire Diamond"},
    {img:LOL,name:"league of legends riot points RP "},
    {img:original,name:"Apex Legends Coins"},
    {img:PS,name:"playsation psn giftcard"},
    {img:sg,name:"valorent points vp"},
    {img:Steam,name:"steam gift cards"},
    {img:UC,name:"pubg mobile uc"}
];

const IconsStoreContainer=styled.div`
display: flex;
gap: 30px;
flex-wrap: wrap;
padding-right: 20px;

@media screen and (max-width: 1200px) {
display: flex;
align-items: center;
justify-content: center;
margin-top: 40px;
}

@media screen and (max-width: 500px) {
    align-items: center;
    justify-content: center;
    padding-right: 0px;
    margin-top: 50px;
}
`

const Container = styled.div`
position: relative;
width: 50%;
height: 100%;
@media screen and (max-width: 1200px) {
width: 90%;

}


`




const MiddleSectionSecondSide = (props) => {
    let delayTime = 0.3
        return (
            <Container>
                <IconsStoreContainer>
                     {hatsArr.map((e,index)=><IconContainer  startnotify={props.startnotify}  delay={delayTime*index} image={e}/>)}
                </IconsStoreContainer>
            </Container>
          )

}

export default MiddleSectionSecondSide


const IconContainerB = styled.div`
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    min-height: 140px;
    min-width: 140px;
    max-height: 140px;
    max-width: 140px;
    position: relative;
    &::after{
        position: absolute;
        inset: 0px;
        height: 100%;
        width: 100%;
        background: linear-gradient(179.63deg, #FFFFFF 0.32%, #181B31 214.64%);
        background-blend-mode: overlay;
        mix-blend-mode: overlay;
        opacity: 0.07;
        content: "";
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        border-radius:30px;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    p{
        z-index: 1561615;
        color: white;
        font-size: 1.5rem;
        height: 30px;
        margin: 0;
        cursor: pointer;
        background-color: rgba(255,255,255,0.1);
        padding: 2px 27px 2px 27px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;
        
        &:hover{
            transition: 0.5s;
            background-color: rgba(255,255,255,0.3);
        }
    }
    img{
        max-height: 70%;
    }


    @media screen and (max-width: 500px) {
    min-height: 120px;
    min-width: 120px;
    max-height: 120px;
    max-width: 120px;
    img{
        max-width: 70%;
    }
    }


`
const ImgContainer= styled.div`
    position: relative;
    height: 80px;
    @media screen and (max-width: 500px) {
        display: flex;
        align-items: center;
        justify-content: center;

    }
`

  

const IconContainer = (props) => {
   
    const cardVariants = {
        offscreen: {
          opacity:0
        },
        onscreen: {
          opacity:1,
          transition: {
            type: "spring",
            delay:props.delay,
            bounce: 0.4,
            duration: 1.5
          }
        }
      };
    return(
        <IconContainerB                 
        as={motion.div}
        className="card-container"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
        variants={cardVariants}
        >
            <ImgContainer  >
            <img  alt={props.image.text} src={props.image.img}/>
            </ImgContainer>
            <p onClick={()=>{
                        window.location.href = "https://store.t7dgaming.com/"
                     }} > Buy</p>
        </IconContainerB>
    )
}