import React from 'react'

 const Home = (props) => {
  const [hoverOn,sethoverOn]=React.useState(false)
  const changeColorOnHover=(data)=>{
  if(data === 'onMouseEnter'){
    sethoverOn(true)
  }else{
    sethoverOn(false)
  }
  }
  return (
<svg onMouseEnter={()=>{changeColorOnHover("onMouseEnter")} } onMouseLeave={()=>changeColorOnHover("onMouseLeave")}  id="HomeI" xmlns="http://www.w3.org/2000/svg" width="46" height="61.166" viewBox="0 0 46 61.166">
  <defs>
    <filter id="home_icon" x="0" y="0" width="46" height="46.003" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g  transform="translate(-55 -209.834)">
    <text  transform="translate(56 266)" fill={(props.select||hoverOn)?"#FB5D38":"#9ca2cf"}  font-size="15" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Home</tspan></text>
    <g transform="matrix(1, 0, 0, 1, 55, 209.83)" filter="url(#home_icon)">
      <path id="home_icon-2" data-name="home icon" d="M27.248,12.179l0,0L15.823.755a2.577,2.577,0,0,0-3.646,0L.76,12.171l-.012.012a2.577,2.577,0,0,0,1.716,4.392q.039,0,.079,0H3v8.406A3.021,3.021,0,0,0,6.016,28h4.469a.82.82,0,0,0,.82-.82V20.591a1.378,1.378,0,0,1,1.377-1.377h2.636a1.378,1.378,0,0,1,1.377,1.377v6.591a.82.82,0,0,0,.82.82h4.469A3.021,3.021,0,0,0,25,24.985V16.579h.422a2.578,2.578,0,0,0,1.824-4.4Zm0,0" transform="translate(9 6)" fill={(props.select||hoverOn) ?"#FB5D38":"#9ca2cf"}/>
    </g>
  </g>
</svg>

  )
}

export default Home