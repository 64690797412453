import React from 'react'

const News = (props) => {
  const [hoverOn,sethoverOn]=React.useState(false)
  const changeColorOnHover=(data)=>{
  if(data === 'onMouseEnter'){
    sethoverOn(true)
  }else{
    sethoverOn(false)
  }
  }



  return (
<svg id="NewsI" onMouseEnter={()=>{changeColorOnHover("onMouseEnter")} } onMouseLeave={()=>changeColorOnHover("onMouseLeave")}  xmlns="http://www.w3.org/2000/svg" width="40" height="54.166" viewBox="0 0 40 54.166">
  <g  transform="translate(-58 -605.834)">
    <text id="News-2" data-name="News" transform="translate(58 655)" fill={(props.select||hoverOn)?"#FB5D38":"#9ca2cf"} font-size="15" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">News</tspan></text>
    <g id="News_icon" data-name="News icon" transform="translate(64 605.834)">
      <path id="Tracé_2593" data-name="Tracé 2593" d="M337.4,212.343v3.535a3.477,3.477,0,0,0,6.954,0v-3.535a3.477,3.477,0,0,0-6.954,0Zm0,0" transform="translate(-318.838 -197.377)" fill={(props.select||hoverOn)?"#FB5D38":"#9ca2cf"}/>
      <path id="Tracé_2594" data-name="Tracé 2594" d="M300.631,305.266a.825.825,0,0,0-.825.825v.884a4.478,4.478,0,1,1-8.956,0v-.884a.825.825,0,1,0-1.65,0v.884a6.125,6.125,0,0,0,5.3,6.073v1.94h-1.827a.825.825,0,1,0,0,1.65h5.3a.825.825,0,1,0,0-1.65h-1.826v-1.94a6.126,6.126,0,0,0,5.3-6.073v-.884A.825.825,0,0,0,300.631,305.266Zm0,0" transform="translate(-273.291 -288.473)" fill={(props.select||hoverOn)?"#FB5D38":"#9ca2cf"}/>
      <path id="Tracé_2595" data-name="Tracé 2595" d="M16.734,15.143c.059,0,.118,0,.176.007v-.183A5.126,5.126,0,0,1,26.4,12.273V4.8a4.805,4.805,0,0,0-4.8-4.8H4.8A4.805,4.805,0,0,0,0,4.8v19a.825.825,0,0,0,1.353.634l5.074-4.228h8.021a7.794,7.794,0,0,1-.19-1.709v-.884a2.478,2.478,0,0,1,2.475-2.476ZM5.244,5.3H21.153a.825.825,0,1,1,0,1.65H5.244a.825.825,0,0,1,0-1.65Zm7.13,10.489H5.244a.825.825,0,0,1,0-1.65h7.13a.825.825,0,1,1,0,1.65Zm-7.13-4.419a.825.825,0,0,1,0-1.65H15.85a.825.825,0,0,1,0,1.65Zm0,0" transform="translate(0 0)" fill={(props.select||hoverOn)?"#FB5D38":"#9ca2cf"}/>
    </g>
  </g>
</svg>

  )
}

export default News