import React from 'react'
import styled from 'styled-components';
import Links from './links'
import LinksMobile from './linksMobile'
import Logo_T7D from '../../icons/Logo_T7D.png'
const Container = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: var(--navBarWidth);
    background-color: rgba(7,7,7,0.4);
    z-index: 166165161651651651651561;
    animation-name: shownavbar;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    svg:hover{
        transition: 0.5s;
        transform:  scale(1.02) rotate(10deg) perspective(1000px) translateZ(50px);
    }

    /*svg:hover,
    svg:focus {
        transition: 0s;
        filter: invert(61%) sepia(47%) saturate(6811%) hue-rotate(338deg) brightness(100%) contrast(98%);    }*/
    @keyframes shownavbar {
        from{
            opacity: 0;
        }
        to{
            opacity: 1;
        }
    }
`
const Logo = styled.div`
 width: 100%;
 margin-top: 40px;
 display: flex;
 align-items: center;
 justify-content: center;
 img{
    max-width: 80%;
 }
`


const ContainerMobileNav = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    height: 60px;
    width: 100vw;
    background-color: rgba(7,7,7,0.4);
    z-index: 1651;
    display: flex;
    align-items: center;
    z-index: 156516516516165;

`

const LogoMobile= styled.div`
 width: 40px;
 height: 40px;
 display: flex;
 align-items: center;
 justify-content: center;
 position: absolute;
 left: 25px;
 z-index: 156516516516165;

 img{
    max-height: 80%;
 }
`

const Index = (props) => {
    const [open,setOpen]=React.useState(false)
    const close=()=>{
        setOpen(false)
    }




    if(window.innerWidth < 1200){
        return(
            <ContainerMobileNav>
                <LogoMobile>
                    <img alt="t7dLogo" src={Logo_T7D}/>
                </LogoMobile>
                <HamberGerMenuIcon  open={open} setOpen={setOpen}/>
                <LinksMobile   close={close} open={open}  currentPage={props.currentPage}/>

            </ContainerMobileNav>
        )
    }else{
        return (
            <Container>
                <Logo>
                    <img alt="t7d_Gaming_Logo" src={Logo_T7D}/>
                </Logo>
                <Links currentPage={props.currentPage}/>
            </Container>
          ) 
    }

}

export default Index







const HamberGerMenuIcon=(props)=>{
    const clickMenu=()=>{
        document.getElementsByClassName("container")[0].classList.toggle("change");
        props.setOpen(old=>!old)
    }
    const closeHamburgerMenu = () => {
        
    }
    return(
        <div class="container"  onClick={clickMenu}>
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
        </div>
    )
}


