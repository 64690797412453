import React from 'react'
import styled from 'styled-components'
import Maps from '../../images/contactimages/Map.png'

import user from '../../icons/user.svg'
import chat from '../../icons/chat.svg'
import email from '../../icons/email.svg'
import phone from '../../icons/phone.svg'
import Subject from '../../icons/Subject.svg'
import axios from 'axios'

const Container= styled.div`
position: relative;
height: 55vh;
width: 100%;
display: flex;
flex-wrap: nowrap;
align-items: center;
justify-content: center;
        @media screen and (min-width: 1200px) and (max-width:1600px)  {
height: 45vh;

}
@media screen and (max-width: 1200px) {
flex-direction: column;
height: auto;
}

`

const FormAndMap = (props) => {
  return (
    <Container>
          <Form startnotify={props.startnotify} />
        <Map/>
    </Container>
  )
}

export default FormAndMap

const FormContainer = styled.div`
width: 50%;
display: flex;
align-items: flex-start;
justify-content: center;
position: relative;
flex-direction: column;
height: 100%;
        @media screen and (min-width: 1200px) and (max-width:1600px)  {
height: 100%;
}
@media screen and (max-width: 1200px) {
width: 80%;
}
@media screen and (max-width: 500px) {
width: 100%;
}
`

const Header = styled.div`
height: 10%;
display: flex;
align-items: flex-start;
justify-content: flex-start;
width: 100%;
h1{
    color: white;
    font-size: 1.5rem;
    
}
    @media screen and (max-width: 500px) {
        margin-top: 20px;
        margin-left: 20px;
    }
`

const FormT = styled.div`
height: 75%;
width: 100%;
position: relative;
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: center;
gap: 30px;
padding: 30px;
padding-left: 20px;
padding-right: 20px;
        @media screen and (min-width: 1200px) and (max-width:1600px)  {
padding: 10px;
gap: 10px;
}
box-sizing: border-box;
&::after{
position: absolute;
height: 100%;
width: 100%;
background: linear-gradient(90deg, #A259FF 0%, #18A0FB 108.9%);
  opacity: 0.20;
  mix-blend-mode: overlay;
  content: "";
  top: 0;
  left: 0;
  border-radius: 21px;
  z-index: 1;

}
    @media screen and (max-width: 500px) {
        width: 95%;
        margin: 0 auto;
        margin-top: 30px;
        flex-direction: column;
    }
`
const InputContainer= styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
    z-index: 15665;
        @media screen and (max-width: 500px) {
        width: 90%;
        }
    
`
const Label= styled.div`
 color: #9ca2cf;
 p{
    font-size: 1.3rem;
    margin: 2px;
 }
`

const UnderLabel = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    
    mix-blend-mode: overlay;
    border-radius: 16px;
        background: linear-gradient(90deg,rgb(162 89 255 / 7%) 0%,rgb(24 160 251 / 2%) 108.9%);

    input{
        width: 80%;
        padding: 17px;
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: transparent;
        border: none;
        outline: none;
        @media screen and (max-width: 500px) {
        padding: 10px;
        }
        ::placeholder{
            text-transform:capitalize;
        }
    }
    textarea{
        width: 100%;
        background-color: transparent;
        border: none;
        resize: none;
        opacity: 0.63;
        outline: none;
        padding-top: 10px;
        padding-left: 10px;
    }
    img{
        margin-left: 15px;
    }
`
const ButtonContainer= styled.div`
position: relative;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
button{
    outline: none;
    border: none;
    opacity: 0.92;
  border-radius: 16px;
  background-image: linear-gradient(92deg, #fb5d38 18%, #ff465e 279%);
  padding: 10px;
  width: 80%;
  color: white;
  cursor: pointer;
  z-index: 1561656;
}
`
const Form = (props) =>{
    const [requestData,setrequestData]=React.useState({
        "subjectType":"62a9f3005ca191a004d0b8d9"

    })

    const inputHandler=(e)=>{
        const {name,value}=e.target
        setrequestData(old=>{
            return{
                ...old,
                [name]:value
            }
        })
    }
    const sendRequest = ()=>{
        axios.post('https://staging.support.t7d.io/supportcases/createCustomSupportCase',requestData).then(data=>{
            props.startnotify()
        }).catch(error=>{
            console.log(error)
        })
    }

    return (<FormContainer>
        <Header><h1>Get in touch</h1></Header>
        <FormT>
            <InputContainer><Label><p>Name</p></Label><UnderLabel><img alt="nameContact" src={user}/><input  onChange={inputHandler} name="name" placeholder='Name'/></UnderLabel></InputContainer>
            <InputContainer><Label><p>E-mail</p></Label><UnderLabel><img alt="emailContact" src={email}/><input onChange={inputHandler} name="email" placeholder='email'/></UnderLabel></InputContainer>
            <InputContainer><Label><p>Phone</p></Label><UnderLabel><img src={phone}/><input alt="phoneContact" onChange={inputHandler} name="phone" placeholder='phone'/></UnderLabel></InputContainer>
            <InputContainer><Label><p>Subject</p></Label><UnderLabel><img src={Subject}/><input alt="typeContact"  name="subjectType" placeholder='subject'/></UnderLabel></InputContainer>
            <InputContainer style={{ width: "85%" }}><Label><p>Message</p></Label><UnderLabel style={{ alignItems: 'flex-start' }}><img style={{ marginTop: "10px" }} src={chat} /><textarea onChange={inputHandler} name="content" placeholder='Type your message here
            ' style={{ height: "70px" }} /></UnderLabel></InputContainer>
            <ButtonContainer>
                
                <button onClick={sendRequest}>Send Message</button>
            </ButtonContainer>
        </FormT>
    </FormContainer>)
}
/*********************************/
const MapContainer = styled.div`
width: 30%;
display: flex;
align-items: center;
justify-content: center;
@media screen and (max-width: 1200px) {
width: 80%;
margin-bottom: 70px;
}
@media screen and (max-width: 500px) {
width: 80%;
margin-bottom: 230px;
}
img{
        max-width: 100%;
    }
`
const Map = () =>{
    return (<MapContainer>
        <img alt="maps" src={Maps}/>
    </MapContainer>)
}