import React from 'react'
import styled from 'styled-components'
import storePhoneImages from '../../images/storePhoneImages.png'
import { motion } from "framer-motion";

const Container = styled.div`
position: relative;
width: 50%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
@media screen and (max-width: 1200px) {
display: none;
}

`
const ImagesContainer = styled.div`
position: relative;
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
gap: 20px;
max-width: 700px;
display:flex;
align-items:center;
justify-content:center;
img{
  max-width:90%
}
`

const cardVariants = {
  offscreen: {
    opacity:0
  },
  onscreen: {
    opacity:1,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 1.5
    }
  }
};


const middleSectionFirstSide = () => {
  return (
        <Container>
        <ImagesContainer as={motion.div}
            className="card-container"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
        >
          
            <motion.img variants={cardVariants} src={storePhoneImages}/>
        </ImagesContainer>
    </Container>
  )
}

export default middleSectionFirstSide