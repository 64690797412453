import React from 'react'
import styled, { css } from 'styled-components'; // Import the 'css' helper function from 'styled-components'
import alaanlogo from '../../components/alanvote/Logo_Al_aan.webp'
import light from '../../components/alanvote/light.png'
import Rectongle from '../../components/alanvote/Rect.svg'
import Close from '../../components/alanvote/close_icon.svg'
import Platfrom from '../../components/platfrom'

const Container=styled.div`
transition: 0.8s;

position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 15615515616651;
background-color: rgba(0,0,0,0.5);
opacity: ${(props) => (props.isModalVisible ? 1 : 0)};

h1 p{
  font-family: Poppins;

}
`
const InnerContainer = styled.div`
position: fixed;
background-image: linear-gradient(to bottom, #424a88, #272c51);
border-radius: 18px;
width: 785px;
height: 360px;
display: flex;
top: 50vh;
left: 50vw;
transform: translate(-50%,-50%);
z-index: 156156165156165;
flex-direction: column;
.rectongle{
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  height: 100%;
}

@media (orientation: portrait) {
width  :95% ;
height: auto;
padding: 0px 0px 40px 0px;
}
`
const LogoContainer=styled.div`
width: 100%;
height: 20%;
position: relative;
display: flex;
align-items: center;
justify-content: center;
img{
  width: 25%;
  transform: translateY(-30px);
}
@media (orientation: portrait) {
  img{
  width: 35%
  }
}
`
const LineOne=styled.div`
color: white;
font-size: 1.3rem;
width: 100%;
text-align: center;
height: 30%;
display: flex;
align-items: center;
justify-content: center;
@media (orientation: portrait) {
margin: 10px 0 10px 0;
}
`
const LineTwo=styled.div`
height: 12.5%;

p{
  color: white;
font-size: 1.8rem;
text-align: center;
color: #9ca2cf;
color: white;
margin-bottom: 5px;
}
@media (orientation: portrait) {
  p{
    padding: 0 5px 0 5px;
    font-size: 1.5rem;
  }
}
`
const Linethree=styled.div`
height: 12.5%;
p{
  color: white;
  font-size: 1.5rem;
text-align: center;
color: #9ca2cf;
}
@media (orientation: portrait) {
  p{
    padding: 0 5px 0 5px;

    font-size: 1.3rem;
  }
}
`

const Linefour=styled.div`
display: flex;
width: 100%;
height: calc(25% - 20px);
display: flex;
align-items: center;
justify-content: space-evenly;
@media (orientation: portrait) {
  flex-direction: column;
}

`

const Light=styled.div`
position: relative;
transform: translateY(-65px) translateX(35px);
width: 100%;

img{
  width: 100%;
}

`
const Button = styled.div`
  position: relative;
  width: 40%;
  max-width: 200px;
  height: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.7rem;
  font-family: Poppins;
  cursor: pointer;
  border: 1px solid transparent; /* Initial border style */
  @media (orientation: portrait) {
  margin-top: 25px;
  width: 80%;
  }

`;

const DownloadButton = styled(Button)`
      transition: 0.5s;
      border:1px solid #9ca2cf;
      color:#9ca2cf;
        &:hover {
          border:#f24259;
          background-color: #f24259;
          color: white;
      }
`;

const ParticipateButton = styled(Button)`
    background-color:#f24259;
    color:white;
    transition: 0.5s;
    :hover{
      filter: drop-shadow(0 0 0.1rem #fd4c54) drop-shadow(0 0 0.2rem #fd4c54);
    }


`;
const CloseIcon=styled.div`
position: absolute;
top: 20px;
right: 20px;
height: 20px;
width: 20px;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
z-index: 89418948;
img{
  max-width: 90%;
}
`

 const Index = (props) => {
  const [isModalVisible, setModalVisible] = React.useState(props.openQuiz);
  const [openPlatfrom,setopenPlatfrom]=React.useState(false)
  React.useEffect(()=>{
    console.log("isModalVisibleisModalVisible",isModalVisible)
  },[isModalVisible])
  React.useEffect(()=>{
    setModalVisible(props.openQuiz)
  },[props.openQuiz])

  const handleCloseClick = () => {
    setTimeout(() => {
      document.getElementById("containeralaan").style.display="none"
    }, 800);
    setModalVisible(false);
    props.setopenQuiz(false)
  };

  const openGall=()=>{
    setopenPlatfrom(true)
  }
  const closeopenGall=()=>{
    setopenPlatfrom(false)
  
  }


  return (
    <>
      {openPlatfrom&&<ContainerOfPlatrform close={closeopenGall}  comp={<Platfrom/>}>
    
    </ContainerOfPlatrform>}
    {<Container id='containeralaan' isModalVisible={isModalVisible}>
      <Light><img src={light}/></Light>
      <InnerContainer>
      <CloseIcon onClick={handleCloseClick}> <img src={Close}/></CloseIcon>
      <img className='rectongle' src={Rectongle}/>
      <LogoContainer><img src={alaanlogo}/></LogoContainer>
      <LineOne><h1>Weekly Alaan quizzes !</h1></LineOne>
      <LineTwo><p>Participate to the quizzes and attempt to win an amazing price.</p></LineTwo>
      <Linethree><p>Download T7D app or participate directly on T7D website.</p></Linethree>
      <Linefour>
        <DownloadButton onClick={()=>{openGall();handleCloseClick()}}>Download T7D APP</DownloadButton>
        <ParticipateButton onClick={()=>{window.location.href = "https://app.t7dgaming.com/quiz_screen"}}>Participate </ParticipateButton>
      </Linefour>
      </InnerContainer>
    </Container>}
    </>
  )
}


export default Index

const CssContainerOfPlatrform=styled.div`
height: 100vh;
width: 100vw;
position: fixed;
background-color: rgba(0,0,0,0.9);
z-index: 156165165165165;
display: flex;
align-items: center;
justify-content: center;
#platfroms{
  display: flex;
  align-items: center;
  justify-content: center;
}
`

const ContainerOfPlatrform=({comp,close})=>{
  return(
    <CssContainerOfPlatrform onClick={()=>{close()}} className='aaaaaaaaaa'>
      {comp}
    </CssContainerOfPlatrform>
  )
  }