import React from 'react'
import styled from 'styled-components';


import App_store from '../../icons/iconReactComponent/Appstore'
import Google_play from '../../icons/iconReactComponent/Googleplay'
import Huawei from '../../icons/iconReactComponent/Huawei'
import T7dweb from '../../icons/iconReactComponent/t7dwebicon'

import { motion } from "framer-motion"



const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    position: relative;
    left: 0%;
    transform: translateX(-0%);
    margin-top: 30px;
    z-index: 165516516516516516565;
    @media screen and (max-width: 1200px) {
        justify-content: center;
        align-items: center;
        margin-top: 40px;
    }
    @media screen and (max-width: 500px) {
    justify-content: center;
    }
`

const PlatfromContainer = styled.div`
   // border: 1px solid white;
    border-radius: 10px;
    cursor: pointer;
    z-index: 15616565;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border 0.5s;
    opacity: 0;



    img{
        position: absolute;
        cursor: pointer;
        width: 75%;
        height: 75%;

    }
`


const index = (props) => {

    

    
  return (
    <Container id="platfroms">

        <PlatfromContainer  as={motion.div} animate={containerV}   transition={{ type: "spring", duration: 70.8 ,delay:0.3}}>
              {/*<motion.img src={App_store} />*/}
              <a target="_blank" href="https://apps.apple.com/tn/app/t7d/id1634787639"><App_store/></a>
        </PlatfromContainer>
        <PlatfromContainer  as={motion.div} animate={containerV}   transition={{ type: "spring", duration: 70.8 ,delay:0.6}}>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.galactechstudio.t7d"><Google_play/></a>
        </PlatfromContainer>
        <PlatfromContainer   as={motion.div} animate={containerV}   transition={{ type: "spring", duration: 70.8 ,delay:0.9}}>
            <a target="_blank" href=" https://app.t7dgaming.com"><T7dweb/></a>
        </PlatfromContainer>
    </Container>
  )
}

export default index



const containerV = {
      opacity: 1,
      scale: 1,
  };


