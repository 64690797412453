import React from 'react'
import styled from 'styled-components'
import axios from 'axios'
import leftarrow from '../../icons/leftarrow.svg'
import rightarrow from '../../icons/rightarrow.svg'
const Container = styled.div`
position: relative;
height: 100%;
width: 50%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
p{
  color: white;
  font-size: clamp(0.625rem, 0.1852rem + 2.3457vw, 1.5rem);
    width: 80%;
  line-height: 1.6;

}
@media screen and (max-width: 700px) {
display: none;
}
`




const TextContainer= styled.div`
position: relative;
height: 70%;
width: 90%;

display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 20px;
h1{
color:white;
text-align: left;
width: 100%;
padding-left: 10%;
font-size: 2rem;
}
p{
color:white;    
    color: #9ca2cf;
}

&::after {
    position: absolute;

    inset: 0px;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
background: linear-gradient(90deg,#A259FF 0%,#18A0FB 108.9%);
    background-blend-mode: overlay;
    mix-blend-mode: overlay;
    opacity: 0.30;
        content: "";
    border-radius: 15px;

}
`



const SecondSection = (props) => {
    const [texts, setText] = React.useState([
        {
          title:'Nilah, the Joy Unbound',
          text:' Nilah wields an illuminated whip to hit its target from a close range. Her whip allows her to get closer to her opponents quicker by pulling her towards them. Such ability allows her to overpower other champions that rely on range. She is also able to to use a whirlpool-like attack that hits nearby enemies'      
        },
        {
          title:"Pearl: The underwater city",
            text: `
                  The newest Valorant season, Episode 5: Dimension, is approaching and along with it emerges the new underwater map: Pearl. The new map will be released on June 22nd. Pearl will be the first map added since last year and will not include any teleporters, ropes or doors
            ` 
        },
        {
          title:"Vanguard Season 3",
            text: `Call of Duty Mobile's Vanguard Season Three has now begun and with it came new content for players to consume. Introducing a new map: Mayhem as well as additional features to already existing ones, adding new mods and a a whole lot of new elements for the ranked play feature, players are in for a treat.
            `  
        },
        {
          title:"Free Fire's 5th Anniversary",
            text: `
Free Fire's 5th Anniversary: 
Free Fires celebrates its 5th anniversary through releasing a new calendar, now available for players. The anniversary rewards will include a myriad of new features such as ,Nexterra, a brand new futuristic looking map, a new diamond store and various in-game rewards that players can gather within the upcoming period.
            `  
        }
    ])
  return (
    <Container id="SecondSection">
          <TextContainer>
              <h1>{texts[props.changeText] === undefined ? texts[0].title: texts[props.changeText].title}</h1>
              <p>
                  {texts[props.changeText] === undefined ? texts[0].text: texts[props.changeText].text}
        </p>
      </TextContainer>
    </Container>
  )
}

export default SecondSection