import React from 'react'
import styled from 'styled-components'
import adress from '../../images/contactimages/adress_icon.png'
import email from '../../images/contactimages/email_icon.png'
import phone from '../../images/contactimages/phone_icon.png'

const Container = styled.div`
    position: relative;
    height: 17vh;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    left: 50%;
    transform: translateX(-50%);
    gap: 20px;
    img{
        width: 70%;
    }
@media screen and (max-width: 500px) {
    margin-top: 40px;
    width: 95% !important;
    flex-direction: column;
    height: auto;
}
`

const Socieal = () => {

  return (
      <Container>
        <Card img={phone} name="phone" text="+216 54 790 406" />
        <Card img={email} name="email" text="support@T7Dgaming.com"/>
        <Card img={adress} name="addres" text="the PLACE offices , Dubai Chambers extension building,2nd Floor"/>        
    </Container>
  )
}

export default Socieal

const CardContainer = styled.div`
position: relative;
height: 130px;
width: 200px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
&::after{
    position: absolute;
    inset: 0px;
    height: 100%;
    width: 100%;
background: linear-gradient(90deg, #A259FF 0%, #18A0FB 108.9%);
    background-blend-mode: overlay;
    mix-blend-mode: overlay;
    opacity: 0.07;
    content: "";
    border-radius: 15px;
}


p{
    position: relative;
    color: white;
    margin-top: 5 0px;
    font-size: 1rem;
    
}
@media screen and (max-width: 500px) {
height: 120px;
}
`
const ImageContainer= styled.div`
position: relative;
height: 60%;
display: flex;
align-items: center;
justify-content: space-around;
flex-direction: column;
width: 100%;
gap: 30px;

&::after{
    content: attr(data-text);
    color: #fe4b57;
    font-size: 1.4rem;
    font-weight: bold;
      font-family: 'Poppins', sans-serif;
text-transform:capitalize;
}
&::before{
    content: "";
    color: white;
    background-color: red;
    height: 120%;
    width: 50%;
    position: absolute;
    box-shadow: 0 3px 6px 0 rgba(99, 2, 2, 0.16);
    opacity: 0.09;
    top: 0;
    background: linear-gradient(90deg, #A259FF 0%, #18A0FB 108.9%);
    border-bottom-left-radius: 21px;
    border-bottom-right-radius: 21px;

}
img{
    position: relative;
    top: 20%;
    max-width: 25px;
    padding: 5px;
}

@media screen and (max-width: 500px) {
&::after{
    content: attr(data-text);
    color: #fe4b57;
    font-size: 1.2rem;
    font-weight: bold;
    transform: translateY(-15px);
}
img{
    max-width: 25px;
}
}
`

const TextContainer= styled.div`
position: absolute;
height: 10%;
bottom: 13%;
display: flex;
align-items: center;
justify-content: center;
p{
    margin-top: 3%;
    color: #9ca2cf;
    text-align: center;
}
@media screen and (max-width: 500px) {
    bottom: 15%;
    p{
        margin-top: 10%;

    }
}

`

const Card=(props)=>{
    return(<CardContainer>
            <ImageContainer data-text={props.name}><img alt="socials" src={props.img} /></ImageContainer>
            <TextContainer><p>{props.text}</p></TextContainer>
        </CardContainer>
    )
}