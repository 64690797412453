import React from 'react'
import styled from 'styled-components'
import s from '../../images/storeImages/shab.png'


const ImageContainer = styled.div`
position: absolute;
bottom: 0;
height: 700px;
width: 300%;
bottom: -25%;
transform: translateX(-50%);
z-index: 156;
background-image: url('http://demos.creative-tim.com/paper-kit-2/assets/img/clouds.png');
background-repeat: repeat-x;
z-index: 1;
opacity: 0.5;
//  -webkit-animation: cloudLoop 80s linear infinite;
  animation: cloudLoop 80s linear infinite;
  @keyframes cloudLoop {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}

`


const shab = () => {
  return (
    <ImageContainer>
    </ImageContainer>
  )
}

export default shab