import './App.scss';
import Home from './pages/home'
import Tournaments from './pages/tournaments'
import Store from './pages/store'
import News from './pages/news'
import ContactUs from './pages/contactus'
import Termsandconditions from './pages/Termsandconditions'
import Loading from './pages/loading'
import Privacy from './pages/Privacy'
import EmailVerification from './pages/emailVerification'
import Newarticles from './pages/newarticles'
import SideBar from './components/sideBar'
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import btnSingUp from './icons/btnSingUp.svg'
import quizLogo from './icons/quizllogo.png'
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

import Platfrom from './components/platfrom'
import Alaan  from './components/alanvote'
function App() {
  const [scrollPoetion,setScrollPoetion]=React.useState(0)
  const [scrolloffsetHeight, setscrollOffsetHeight] = React.useState()
  const [loading,setLoading]=React.useState(false)
  const [openPlatfrom,setopenPlatfrom]=React.useState(false)
  const [openQuiz,setopenQuiz]=React.useState(true)

  React.useEffect(()=>{

   document.addEventListener("wheel", function (e) {
      if(e.deltaY>0){
       // document.querySelector("body").scrollBy(0,window.innerHeight)
      }else{
      //  document.querySelector("body").scrollBy(0,-window.innerHeight)
      }
      return false;
  }, true)
      document.querySelector("#root").addEventListener("scroll", (e=>{
        let top  = window.pageYOffset || document.querySelector("#root").scrollTop
        setScrollPoetion(document.querySelector("#root").clientHeight)
        setscrollOffsetHeight(top)
        })
    )
  
  },[])

const openGall=()=>{
  setopenPlatfrom(true)
}
const closeopenGall=()=>{
  setopenPlatfrom(false)

}
  return (
    <div className="App">
      {/*<Alaan setopenQuiz={setopenQuiz} openQuiz={openQuiz}/>*/}
      {/*<Loading/>*/}
      <Routes>
        <Route path="/" element={<>
          {openPlatfrom&&<ContainerOfPlatrform close={closeopenGall}  comp={<Platfrom/>}>
            
          </ContainerOfPlatrform>}
          <div id='ButtonActionContainer'>
            <button onClick={openGall} id="singUpForTheBeta">{ window.innerWidth<700?<img src={btnSingUp}/>:"Download T7D Now!"}</button>
  {         <button  id="Participatetoquizzes" onClick={()=>{
                  setopenQuiz(true)
                  document.getElementById("containeralaan").style.display="block";

            }}>{ window.innerWidth<700?<img src={quizLogo}/>:"Participate to quizzes!"}</button>}
          </div>
          <SideBar/>
          <Home/>
          <Tournaments  scrollPosetion={scrollPoetion} scrolloffsetHeight={scrolloffsetHeight}/>
          <Store/>
          <News/>
          <ContactUs/>
        </>} />
        <Route path="/emailVerification/:id" element={<EmailVerification  />} />
        <Route path="/Termsandconditions" element={<Termsandconditions />} />
        <Route path="/Privacy" element={<Privacy  />} />
        <Route path="/news/:id" element={<Newarticles  />} />

      </Routes>

    </div>
  );
}

export default App;


const CssContainerOfPlatrform=styled.div`
height: 100vh;
width: 100vw;
position: fixed;
background-color: rgba(0,0,0,0.9);
z-index: 156165165165165;
display: flex;
align-items: center;
justify-content: center;
#platfroms{
  display: flex;
  align-items: center;
  justify-content: center;
}
`

const ContainerOfPlatrform=({comp,close})=>{
return(
  <CssContainerOfPlatrform onClick={()=>{close()}} className='aaaaaaaaaa'>
    {comp}
  </CssContainerOfPlatrform>
)
}