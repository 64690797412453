import React from 'react'
import styled from 'styled-components';
import b2 from '../../images/b2.png'
import Mobile_tournament from '../../images/Mobile_tournament.png'
import Light from './light'
import RightSideCornerImage from './rightSideCornerImage'
import Header from './header'
import MiddleSectionFirstSide from './middleSectionFirstSide'
import MiddleSectionSecondSide from './middleSectionSecondSide'

const Container = styled.div`
height: 100vh;
width: 100vw;
left: 0;
top: 0;
//background-image: url(${(window.innerWidth<1200)? b2: b2});
background-size: 100% 100%;
position: relative;
z-index: 5555555;
min-height:800px;

@media screen and (max-width: 1200px) {
  background-size:cover;
height: auto !important;
}
`

const Wrapper = styled.div`
position: relative;
    left: calc(var(--navBarWidth)  ) ;
    width: calc(100vw - var(--navBarWidth)) ;
    height: 100vh;
    z-index: 156165561;
    @media screen and (max-width: 1200px) {
      left: 0;
      width: 100vw;
      height: auto !important;
    }
;
`

const MiddleSection=styled.div`
width: 100%;
height: calc(100% - 200px);
display: flex;
flex-wrap: nowrap;
@media screen and (max-width: 1200px) {
  left: 0;
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}
`





const index = (props) => {
  return (
    <Container id="Tournament">
      <RightSideCornerImage/>
      <Wrapper id="wrapperTournament">
          <Header scrolloffsetHeight={props.scrolloffsetHeight} scrollPosetion={props.scrollPosetion} />
          <MiddleSection id="MiddleSectionTournament">
              <MiddleSectionFirstSide  scrolloffsetHeight={props.scrolloffsetHeight} scrollPosetion={props.scrollPosetion}/>
              <MiddleSectionSecondSide scrolloffsetHeight={props.scrolloffsetHeight} scrollPosetion={props.scrollPosetion}/>
          </MiddleSection>
      </Wrapper>
    </Container>
  )
}

export default index