import React from 'react'

const t7dwebicon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="152.595" height="46.406" viewBox="0 0 152.595 46.406">
  <g id="T7D_button" data-name="T7D button" transform="translate(-674.448 -633.333)">
    <g id="App_store" data-name="App store" transform="translate(674.448 633.333)">
      <g id="Groupe_52" data-name="Groupe 52" transform="translate(0 0)">
        <g id="Groupe_51" data-name="Groupe 51">
          <g id="Groupe_50" data-name="Groupe 50">
            <path id="Tracé_52" data-name="Tracé 52" d="M2314.7,1058.34a4.857,4.857,0,0,1,4.851,4.852V1097.8a4.858,4.858,0,0,1-4.851,4.852H2173.9a4.857,4.857,0,0,1-4.851-4.852v-34.609a4.856,4.856,0,0,1,4.851-4.852h140.8m0-1.047H2173.9a5.9,5.9,0,0,0-5.9,5.9V1097.8a5.9,5.9,0,0,0,5.9,5.9h140.8a5.9,5.9,0,0,0,5.9-5.9v-34.609a5.9,5.9,0,0,0-5.9-5.9Z" transform="translate(-2168.002 -1057.293)" fill="#e8e8e8"/>
          </g>
        </g>
      </g>
    </g>
    <g id="Groupe_18817" data-name="Groupe 18817">
      <text id="Go_and_visit_our" data-name="Go and visit our" transform="translate(716.859 647.552)" fill="#e8e8e8" font-size="8" font-family="CenturyGothic, Century Gothic"><tspan x="0" y="0">Go and visit our</tspan></text>
      <text id="T7D_website" data-name="T7D website" transform="translate(717.413 668.371)" fill="#e8e8e8" font-size="16" font-family="Poppins-SemiBold, Poppins" font-weight="600"><tspan x="0" y="0">T7D website</tspan></text>
      <path id="web_1_" data-name="web (1)" d="M25.948,38.552a11.607,11.607,0,0,0,.395,2.466.543.543,0,0,0,.53.406h3.371a.547.547,0,0,0,.546-.605,31.922,31.922,0,0,1-.062-5.847.547.547,0,0,0-.547-.594H26.872a.543.543,0,0,0-.53.406A11.763,11.763,0,0,0,26,36.648a12.326,12.326,0,0,0-.062,1.508Zm5.141-6.382a25.781,25.781,0,0,1,.886-3.544A.55.55,0,0,0,31.142,28a12.113,12.113,0,0,0-3.54,3.792.548.548,0,0,0,.472.828h2.474A.545.545,0,0,0,31.089,32.17Zm11.9-.2A20.154,20.154,0,0,0,41.131,26.5a.531.531,0,0,0-.356-.276,12.034,12.034,0,0,0-5.652,0,.531.531,0,0,0-.356.276,20.156,20.156,0,0,0-1.856,5.471.547.547,0,0,0,.538.655h9A.547.547,0,0,0,42.987,31.967Zm2.363.655h2.474a.548.548,0,0,0,.472-.828A12.108,12.108,0,0,0,44.756,28a.55.55,0,0,0-.833.624,25.792,25.792,0,0,1,.886,3.545A.545.545,0,0,0,45.35,32.622Zm.3,8.8h3.371a.543.543,0,0,0,.53-.406,11.747,11.747,0,0,0,.346-1.864,12.316,12.316,0,0,0,.062-1.532c0-.249-.04-.76-.062-.974a11.763,11.763,0,0,0-.346-1.864.543.543,0,0,0-.53-.406H45.718a.547.547,0,0,0-.547.594,31.921,31.921,0,0,1-.062,5.847A.547.547,0,0,0,45.655,41.424Zm-.972,2.194a24.3,24.3,0,0,1-1.076,3.752.549.549,0,0,0,.809.658A12.113,12.113,0,0,0,48.3,44.009a.548.548,0,0,0-.472-.829h-2.6a.544.544,0,0,0-.538.438ZM32.292,47.37a24.3,24.3,0,0,1-1.076-3.751.544.544,0,0,0-.538-.438h-2.6a.548.548,0,0,0-.472.829,12.115,12.115,0,0,0,3.881,4.019.549.549,0,0,0,.809-.658Zm10-4.19H33.6a.547.547,0,0,0-.535.671,18.336,18.336,0,0,0,2.183,5.592.531.531,0,0,0,.358.247,12.039,12.039,0,0,0,4.68,0,.531.531,0,0,0,.358-.247,18.332,18.332,0,0,0,2.183-5.592A.547.547,0,0,0,42.3,43.18Zm-9.917-5.631a29.63,29.63,0,0,0,.195,3.389.547.547,0,0,0,.545.486H42.78a.547.547,0,0,0,.545-.486,29.733,29.733,0,0,0,.075-6.06.547.547,0,0,0-.547-.5H33.045a.547.547,0,0,0-.547.5C32.42,35.753,32.379,36.646,32.379,37.549Z" transform="translate(662.067 618.451)" fill="#e8e8e8" fill-rule="evenodd"/>
    </g>
  </g>
</svg>
  )
}

export default t7dwebicon