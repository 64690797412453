import React from 'react'
import styled from 'styled-components';
import  Home_icon from '../../icons/iconReactComponent/home'
import  Contact_icon from '../../icons/iconReactComponent/contact'
import  News_icon from '../../icons/iconReactComponent/news'
import  Store_icon from '../../icons/iconReactComponent/store'
import  Tournament_icon from '../../icons/iconReactComponent/tournament'


const LinksContainer = styled.div`
    height: 70%;
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    z-index: 1656165;
    box-sizing: border-box;
        img{
        cursor: pointer;
        z-index: 16515655;

        }
`
const IconContainer = styled.div`
    margin-top: 50px;
    height: 80%;
    z-index: 1561665;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
        cursor: pointer;
        transition: 0.2s;
    }
`

const SideLine = styled.div`
position: absolute;
left: 0px;
box-shadow: 3px 22px 35px 0 rgba(246, 0, 0, 0.75);
background-image: linear-gradient(to bottom, #fb5d38, #ff465e);
height: 10vh;
width: 10px;
transition: 0.5s;
`

const Links = () => {
    const [currentPage,setCurrentPage]= React.useState("Home")
    const [currentPageFromScrolling, setcurrentPageFromScrolling] = React.useState("Home")
    console.log(currentPageFromScrolling)
    React.useEffect(()=>{
        let posetionOfHome  = document.getElementById('Home').offsetTop
        let posetionOfTournament  = document.getElementById('Tournament').offsetTop
        let posetionOfStore  = document.getElementById('Store').offsetTop
        let posetionOfNew  = document.getElementById('News').offsetTop
        let posetionOfContact  = document.getElementById('Contact').offsetTop
 
        let scrollFunction =   document.querySelector("#root").addEventListener("scroll", (e=>{
            let top = window.pageYOffset || document.querySelector("#root").scrollTop
    
            if(top >= posetionOfContact && currentPageFromScrolling !== 'Contact'){
                document.getElementById('SideLine').style.top=document.getElementById('ContactI').offsetTop+"px"
                setcurrentPageFromScrolling('Contact')
            } else if (top >= posetionOfNew && currentPageFromScrolling !== 'News') {
                document.getElementById('SideLine').style.top=document.getElementById('NewsI').offsetTop+"px"
                setcurrentPageFromScrolling('News')
            }else if(top >= posetionOfStore && currentPageFromScrolling !== 'Store'){
                document.getElementById('SideLine').style.top=document.getElementById('StoreI').offsetTop+"px"
                setcurrentPageFromScrolling('Store')
            }else if(top >= posetionOfTournament && currentPageFromScrolling !== 'Tournament'){
                document.getElementById('SideLine').style.top=document.getElementById('TournamentI').offsetTop+"px"
                setcurrentPageFromScrolling('Tournament')
            }else if(top === 0 ){
                document.getElementById('SideLine').style.top=document.getElementById('HomeI').offsetTop+"px"
                setcurrentPageFromScrolling('Home')
            }
        }));
        //console.log(document.getElementById('Home').offsetTop)
        setTimeout(() => {
            document.getElementById('SideLine').style.top=document.getElementById('HomeI').offsetTop+"px"

        }, 500);

    },[])


    
  return (
    <LinksContainer>
    <SideLine id="SideLine" />
        <IconContainer id="HomeI" onClick={()=>{setcurrentPageFromScrolling("Home");document.getElementById('Home').scrollIntoView()}}>
              <Home_icon select={currentPageFromScrolling === 'Home'} />
        </IconContainer>
        <IconContainer id="TournamentI" onClick={()=>{setcurrentPageFromScrolling("Tournament");document.getElementById('Tournament').scrollIntoView()}}>
              <Tournament_icon select={currentPageFromScrolling === 'Tournament'} />

        </IconContainer>
        <IconContainer id="StoreI" onClick={()=>{setcurrentPageFromScrolling("Store");document.getElementById('Store').scrollIntoView()}}>
            <Store_icon select={ currentPageFromScrolling === 'Store'}/>
        </IconContainer>
        <IconContainer id="NewsI" onClick={()=>{setcurrentPageFromScrolling("News");document.getElementById('News').scrollIntoView()}}>
            <News_icon select={ currentPageFromScrolling === 'News'}/>
        </IconContainer>
        <IconContainer id="ContactI" onClick={()=>{setcurrentPageFromScrolling("Contact");document.getElementById('Contact').scrollIntoView()}}>
            <Contact_icon select={ currentPageFromScrolling === 'Contact'}/>
        </IconContainer>
    </LinksContainer>
  )
}

export default Links



