import React from 'react'

const Contact = (props) => {
    
  const [hoverOn,sethoverOn]=React.useState(false)
  const changeColorOnHover=(data)=>{
  if(data === 'onMouseEnter'){
    sethoverOn(true)
  }else{
    sethoverOn(false)
  }
  }

  return (
<svg  onMouseEnter={()=>{changeColorOnHover("onMouseEnter")} } onMouseLeave={()=>changeColorOnHover("onMouseLeave")}id="ContactI" xmlns="http://www.w3.org/2000/svg" width="83" height="61.334" viewBox="0 0 83 61.334">
  <g id="Contact_us" data-name="Contact us" transform="translate(-35 -598.666)">
    <text id="Contact_us-2" data-name="Contact us" transform="translate(35 655)" fill={(props.select||hoverOn)?"#FB5D38":"#9ca2cf"} font-size="15" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Contact us</tspan></text>
    <path id="support_icon" data-name="support icon" d="M22.152,47.166a2.307,2.307,0,0,0-2.307-2.307H17.02a2.307,2.307,0,1,0,0,4.614h2.825A2.307,2.307,0,0,0,22.152,47.166ZM4.85,41.217a9.992,9.992,0,0,0,1.32.019A6.549,6.549,0,0,0,12.6,46.518h.88a3.621,3.621,0,0,0,0,1.3H12.6a7.845,7.845,0,0,1-7.745-6.6Zm-.659-1.379A5.027,5.027,0,0,1,0,34.881V31.818a5.027,5.027,0,0,1,5.027-5.027h.335a13.114,13.114,0,0,1,26.143,0h.335a5.027,5.027,0,0,1,5.027,5.027v3.063a5.027,5.027,0,0,1-5.027,5.027H30.355a1.114,1.114,0,0,1-1.114-1.114V27.842a10.809,10.809,0,1,0-21.618,0V38.794a1.114,1.114,0,0,1-.611.994,6.8,6.8,0,0,1-2.822.05Z" transform="translate(58 583.937)" fill={(props.select||hoverOn)?"#FB5D38":"#9ca2cf"} fill-rule="evenodd"/>
  </g>
</svg>
  )
}

export default Contact