import React from 'react'
import styled from 'styled-components';
import { motion } from "framer-motion";

const Container = styled.div`
    position: relative;
    height: 200px;
    width: 100%;
    padding-top: 80px;
    box-sizing: border-box;

    h1{
        color: white;
        font-size: 2rem;
        margin: 0;
    }
    p{
        color: white;
        font-size: 1.5rem;
        max-width: 550px;
                color:#9ca2cf;

    }
`
const Text=  styled.div`
    margin-left: 100px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1200px) {
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 20px;

    }   
`
const cardVariants = {
    offscreen: {
      opacity:0,
    },
    onscreen: {
      opacity:1,
      transition: {
        duration: 2
      }
    }
  };

const header = () => {
  return (
    <Container as={motion.div}
    className="card-container"
    initial="offscreen"
    whileInView="onscreen"
    viewport={{ once: true, amount: 0.8 }}
    >
        <Text>
        <motion.h1   variants={cardVariants}>Tournaments</motion.h1>
        <motion.p    variants={cardVariants}>Compete in daily esports tournaments with players all around the world and prove your skills for a chance to win exciting prizes. </motion.p>
        </Text>
    </Container>
  )
}

export default header



