import React from 'react'
import styled from 'styled-components'
import backArrow from '../../icons/Backarrow.svg'
import { useNavigate  ,useSearchParams,useParams} from "react-router-dom";
import backprivecyandterms from '../../images/backprivecyandterms.png'

const Container = styled.div`
position: relative;
width: 100%;
min-height: 100vh;
background-image: url(${backprivecyandterms});
background-size: cover;
padding-bottom: 30px;

`
const Header = styled.div`
position: relative;
height: auto;
width: 90%;
left: 50%;
transform: translateX(-50%);
padding: 30px;
box-sizing: border-box;
cursor: pointer;

`
const TextContainer = styled.div`
position: relative;
height: auto;
width: 90%;
left: 50%;
transform: translateX(-50%);
padding: 30px;
box-sizing: border-box;
h1{
  font-size: 2rem;
  color: white;
}
h2{
  font-size: 1.7rem;
  color: #fe4b57;
}
p{
  font-size: 1.5rem;
    line-height: 1.5;
  color: white;
  color: #a0a5c7;
}
ul,li{
  font-size: 1.3rem;
    line-height: 1.6;
  color: white;
    color: #a0a5c7;

}
&:after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 30px;
  inset: 0px;
    mix-blend-mode: overlay;

 background-color: #FFFFFF ;
 opacity: 0.05;
   background-image: linear-gradient(to bottom, #fff 0%, #181b31 215%);

}
`
const Index = () => {

    const [texts, setText] = React.useState([
        {
          title:'Nilah, the Joy Unbound',
          text:' Nilah wields an illuminated whip to hit its target from a close range. Her whip allows her to get closer to her opponents quicker by pulling her towards them. Such ability allows her to overpower other champions that rely on range. She is also able to to use a whirlpool-like attack that hits nearby enemies'      
        },
        {
          title:"Pearl: The underwater city",
            text: `
                  The newest Valorant season, Episode 5: Dimension, is approaching and along with it emerges the new underwater map: Pearl. The new map will be released on June 22nd. Pearl will be the first map added since last year and will not include any teleporters, ropes or doors
            ` 
        },
        {
          title:"Vanguard Season 3",
            text: `Call of Duty Mobile's Vanguard Season Three has now begun and with it came new content for players to consume. Introducing a new map: Mayhem as well as additional features to already existing ones, adding new mods and a a whole lot of new elements for the ranked play feature, players are in for a treat.
            `  
        },
        {
          title:"Free Fire's 5th Anniversary",
            text: `
Free Fire's 5th Anniversary: 
Free Fires celebrates its 5th anniversary through releasing a new calendar, now available for players. The anniversary rewards will include a myriad of new features such as ,Nexterra, a brand new futuristic looking map, a new diamond store and various in-game rewards that players can gather within the upcoming period.
            `  
        }
    ])

  const navigate = useNavigate();
  const [page, setPage] = React.useState(0)
    let { id } = useParams();
    const goTo = (route) => {
            navigate("/")
  }
  React.useEffect(() => {
    document.querySelector("#root").scrollTop = 0
    
  }, [])

  console.log(id)
  return (
    <Container>
      <Header>
        <img alt="backArrow" onClick={() => goTo('Termsandconditions')} src={backArrow}/>
      </Header>
      <TextContainer>
        <h1>{texts[id-1??0].title}</h1>
        <p style={{marginTop:"30px"}}>
          {texts[id-1??0].text}
        </p>

      </TextContainer>
    </Container>
  )
}

export default Index