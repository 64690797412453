import React from 'react'

const Tournament = (props) => {

  const [hoverOn,sethoverOn]=React.useState(false)
  const changeColorOnHover=(data)=>{
  if(data === 'onMouseEnter'){
    sethoverOn(true)
  }else{
    sethoverOn(false)
  }
  }
        //<svg xmlns="http://www.w3.org/2000/svg" width={props.select?(36.865/1.3) :"36.865"} height={props.select?(36.865/1.3) :"34.744"} viewBox="0 0 36.865 34.744">

  return (
<svg onMouseEnter={()=>{changeColorOnHover("onMouseEnter")} } onMouseLeave={()=>changeColorOnHover("onMouseLeave")} id="TournamentI" xmlns="http://www.w3.org/2000/svg" width="101" height="56.176" viewBox="0 0 101 56.176">
  <g id="Tournaments" transform="translate(-28 -348.824)">
    <text id="Tournaments-2" data-name="Tournaments" transform="translate(28 400)" fill={(props.select||hoverOn)?"#FB5D38":"#9ca2cf"} font-size="15" font-family="Poppins-Regular, Poppins"><tspan x="0" y="0">Tournaments</tspan></text>
    <g id="trophy_icon" data-name="trophy icon" transform="translate(62 348.824)">
      <path id="Tracé_2580" data-name="Tracé 2580" d="M95.33,0H73.909V2.058h2.425V12.3c0,5.256,3.662,9.583,8.168,9.656,4.63.075,8.4-4.28,8.4-9.662V2.058H95.33Z" transform="translate(-68.839)" fill={(props.select||hoverOn)?"#FB5D38":"#9ca2cf"}/>
      <path id="Tracé_2581" data-name="Tracé 2581" d="M130.118,351.236a2.058,2.058,0,0,0-2.058-2.058h-1.613v-4.94a11.7,11.7,0,0,1-3.018.393h-.165a11.6,11.6,0,0,1-2.853-.394v4.942H118.8a2.058,2.058,0,0,0-2.058,2.058v2.451h13.38Z" transform="translate(-107.647 -323.511)" fill={(props.select||hoverOn)?"#FB5D38":"#9ca2cf"}/>
      <path id="Tracé_2582" data-name="Tracé 2582" d="M406.663,60v2.058h3.783v4.63a5.153,5.153,0,0,1-4.346,5.083q-.157.488-.358.965a11.717,11.717,0,0,1-.566,1.155h.125a7.211,7.211,0,0,0,7.2-7.2V60Z" transform="translate(-380.942 -55.884)" fill={(props.select||hoverOn)?"#FB5D38":"#9ca2cf"}/>
      <path id="Tracé_2583" data-name="Tracé 2583" d="M6.41,71.772a5.153,5.153,0,0,1-4.352-5.084v-4.63H5.841V60H0v6.689a7.211,7.211,0,0,0,7.2,7.2H7.34a11.771,11.771,0,0,1-.587-1.2Q6.561,72.239,6.41,71.772Z" transform="translate(0 -55.884)" fill={(props.select||hoverOn)?"#FB5D38":"#9ca2cf"}/>
    </g>
  </g>
</svg>

  )
}

export default Tournament