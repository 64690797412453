import React from 'react'
import styled from 'styled-components';
import b1 from '../../images/b1.png'
import Platfrom from '../../components/platfrom'
import Card  from './card'
import light from '../../images/light.png'
import po from './po.png'
import Smite from './Smite-PNG-Images-HD.png'
import AnimatedText from '../../components/AnimatedText'
import { motion } from "framer-motion";
import { ToastContainer, toast } from 'react-toastify';
const Container = styled.div`
height: 100vh;
width: 100vw;
position: relative;
left: 0;
top: 0;
//background-image: url(${b1});
background-size: cover;
background-attachment: fixed;
z-index: 165165;
min-height:800px;



@media screen and (max-width: 1200px) {
    height:  auto !important;
    position: relative;
    padding-bottom: 90px;
    min-height:0px;

}

`
const Light = styled.div`
    left: calc(var(--navBarWidth));
    top: 0px;
    position: absolute;
    z-index: 1561656;
    height: 100vh;
    width: calc(100vh * ( 6 / 4));
    background-image: url(${light});
    background-size: cover;
    background-position: top;
    z-index: 161656;
    opacity: 0;
    overflow: visible;
    transform: translateX(-20%) !important;
    @media screen and (max-width: 1200px) {
        left: 50% !important;
        transform: translateX(-45%) !important;
    }    
    @media screen and (max-width: 500px) {
        left: 50% !important;
        transform: translateX(-47%) !important;
        top: 30px;
    }    
`
const DescriptionContainer = styled.div`

    left: calc(var(--navBarWidth) + 100px ) ;
    padding: 20px;
    min-width: 40%;
    z-index: 1651651666;
    overflow: visible;
        @media screen and (min-width: 1200px) and (max-width:1600px)  {
      h1{
       font-size: 4rem !important;

        span{
        font-size: 4rem !important;
        }
      }
      p{
        width: 80%;
        span{
        font-size: 1.5rem !important;

        }
      }

    }
    h1{
        font-size: 5rem;
        color: white;
        text-align:left;
        margin: 0;
    }
    p{
        font-size: 1.7rem;
        color: white;
        text-align:left;
        color: #CFCFCF;
        max-width: 700px;
        word-break:normal;
    }

    @media screen and (max-width: 1200px) {
        left: 0;
        width: 100%;
        p{
            font-size: clamp(0.7rem, -1.0rem + 4vw, 1.5rem);
            text-align  : center !important;  
            width: 100%;
            max-width: none;
        }
        h1{
            text-align  : center;  

            font-size: clamp(1rem, -1.5rem + 8vw, 3rem);
        }
}
@media screen and (max-width: 500px) {
    p{
            font-size: clamp(1.2rem, -1.0rem + 4vw, 1.5rem);    
        }
        h1{
            font-size: clamp(3rem, -1.5rem + 8vw, 3rem);
        }

}

`
const MiddleLayerContainer = styled.div`
position: absolute;
display: flex;
align-items: center;
justify-content: center;
top: 50%;
transform: translateY(-50%);
left: var(--navBarWidth);
width: calc(100% - var(--navBarWidth));
z-index: 15615165651615;
@media screen and (max-width: 1200px) {
    position: relative;
    box-sizing:border-box;
    flex-direction: column;
    width: 100vw !important;
    left:0px !important;
    padding: 4%;
    transform: translateY(-65%);
    height: auto;
    top: 80px;
    transform: translateY(-0%);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 90px;

}
`

const ImgsContainer = styled.div`
position: absolute;
top: 0;
right: 0;
height: 100%;
width: 50%;
background-image: url(${po});
background-size: 100%;
background-repeat: no-repeat;

`

const Character = styled.div`
position: relative;
height: 100%;
width: 100%;
img{
position: absolute;
top: 50%;
left: 50%;
max-width: 100%;
transform: translate(-50%,-50%);
animation-name: moveChar;
animation-duration: 12s;
animation-timing-function: linear;
animation-direction: alternate;
animation-iteration-count: infinite;
@keyframes moveChar {
  0%{transform: translate(-50%,-50%) rotate(0deg);}
  25%{transform: translate(-49.5%,-49.5%) rotate(1deg);}
  50%{transform: translate(-50.5%,-50.5%) rotate(0deg);}
  75%{transform: translate(-49.5%,-49.5%) rotate(1deg);}
  100%{transform: translate(-50%,-50%) rotate(0deg);}
}
@media screen and (max-width: 1200px) {
display: none;
}
}
`
const Index = () => {
    const [hidde,setHidde]=React.useState(false)

    const placeholderText = [
        { type: "heading1", text: "Welcome Player!" },
        {
          type: "paragraph",
          text: "Enjoy and participate in a wide range of exclusive casual and professional tournaments and purchase your favorite in-game items and so much more with T7D’s store. Complete your registration to receive your rewards now!"
        }
      ];
    const container = {
        delay: 6.5,
          visible: {
          transition: {
            staggerChildren: 0.025
          }
        }
      };
      React.useEffect(()=>{
        setTimeout(()=>{
            setHidde(true)
        },1500)
      },[])
      const showLight={
        opacity: 1,
        scale: 1,
      }

      const notify = () => toast.info('Coming soon', {
        position: toast.POSITION.BOTTOM_CENTER
    });
      const startnotify=()=>{
        notify()
      }
      const cardVariants = {
    offscreen: {
    opacity: 0
      
    },
    onscreen: {
    opacity: 1
    }
  };
  return (
    <Container id="Home">
      <ImgsContainer>
      <Character>
        <img alt="smite" src={Smite}/>
      </Character>
      </ImgsContainer>
        <ToastContainer  
        
        
        style={{fontSize:"2rem"}}/>
        

        <Light as={motion.div} animate={showLight}   transition={{  duration: 1.8 ,delay:0.5}}>
        </Light>
        <MiddleLayerContainer>
        <DescriptionContainer
        as={motion.div}
        className="card-container"
        initial="offscreen"
        whileInView="onscreen"
        variants={cardVariants}
        transition={{ duration: 1.8, delay: 1 }}
        viewport={{ once: true, amount: 0.8 }}

        >
          <h1>Welcome Challenger! </h1>
          <p>This is T7D Gaming hub, everything a gamer needs in one place. We present to you a wide range of gaming services to compete against other players in e-sport tournaments or to equip yourself with your favourite gaming skins and items. 
            Upgrade your gaming experience to the next level by joining T7D's community.</p>
            <Platfrom startnotify={startnotify}/>
        </DescriptionContainer>
        <Card/>
        </MiddleLayerContainer>
     {/*   <Yassio>
            <img src={LOL_img}/>
        </Yassio>*/}
    </Container>
  )
}

export default Index






