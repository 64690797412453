import React from 'react'
import styled from 'styled-components';
import  Home_icon from '../../icons/iconReactComponent/home'
import  Contact_icon from '../../icons/iconReactComponent/contact'
import  News_icon from '../../icons/iconReactComponent/news'
import  Store_icon from '../../icons/iconReactComponent/store'
import  Tournament_icon from '../../icons/iconReactComponent/tournament'


const LinksContainer = styled.div`
    height: 100vh;
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    z-index: 1656165;
    box-sizing: border-box;
    left: 100%;
    position: absolute;
    background-color: rgba(7,7,7,0.8);
    z-index: 54545445;
    top: 0;
    transition: 0.5s;
        img{
        cursor: pointer;
        z-index: 16515655;
        }
`
const IconContainer = styled.div`
    margin-top: 50px;
    height: 80%;
    z-index: 1561665;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
        cursor: pointer;
        transition: 0.2s;
    }
`

const Links = (props) => {
    const [currentPage,setCurrentPage]= React.useState("Home")
    
    React.useEffect(()=>{
        document.getElementById(currentPage).scrollIntoView();
        props.close()
    },[currentPage])
  return (
    <LinksContainer style={(props.open === true || props.open === undefined) ? {left:"0%"}: {left:"100%"}}>
          <IconContainer onClick={() => { setCurrentPage("Home");document.getElementsByClassName("container")[0].classList.toggle("change");}}>
            <Home_icon select={currentPage === 'Home'}/>
        </IconContainer>
          <IconContainer onClick={() => { setCurrentPage("Tournament");         document.getElementsByClassName("container")[0].classList.toggle("change");}}>
            <Tournament_icon select={currentPage === 'Tournament'}/>
        </IconContainer>
          <IconContainer onClick={() => { setCurrentPage("Store");document.getElementsByClassName("container")[0].classList.toggle("change");}}>
            <Store_icon select={currentPage === 'Store'}/>
        </IconContainer>
          <IconContainer onClick={() => { setCurrentPage("News");document.getElementsByClassName("container")[0].classList.toggle("change");}}>
            <News_icon select={currentPage === 'News'}/>
        </IconContainer>
          <IconContainer onClick={() => { setCurrentPage("Contact");document.getElementsByClassName("container")[0].classList.toggle("change");}}>
            <Contact_icon select={currentPage === 'Contact'}/>
        </IconContainer>
    </LinksContainer>
  )
}

export default Links