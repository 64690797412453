import React from 'react'

const Googleplay = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Google_play" data-name="Google play" width="152.595" height="46.406" viewBox="0 0 152.595 46.406">
  <g id="Groupe_63" data-name="Groupe 63" transform="translate(0 0)">
    <g id="Groupe_62" data-name="Groupe 62">
      <g id="Groupe_61" data-name="Groupe 61">
        <g id="Groupe_60" data-name="Groupe 60">
          <path id="Tracé_76" data-name="Tracé 76" d="M2314.7,500.364a4.857,4.857,0,0,1,4.851,4.852v34.609a4.857,4.857,0,0,1-4.851,4.852H2173.9a4.858,4.858,0,0,1-4.852-4.852V505.215a4.858,4.858,0,0,1,4.852-4.852h140.8m0-1.047H2173.9a5.9,5.9,0,0,0-5.9,5.9v34.609a5.9,5.9,0,0,0,5.9,5.9h140.8a5.9,5.9,0,0,0,5.9-5.9V505.215a5.9,5.9,0,0,0-5.9-5.9Z" transform="translate(-2168 -499.317)" fill="#cbcbcb"/>
        </g>
      </g>
    </g>
  </g>
  <g id="Groupe_90" data-name="Groupe 90" transform="translate(40.874 21.388)">
    <g id="Groupe_89" data-name="Groupe 89">
      <g id="Groupe_79" data-name="Groupe 79" transform="translate(0 0.554)">
        <path id="Tracé_91" data-name="Tracé 91" d="M2567.674,715.051h2.127v4.827a6.757,6.757,0,0,1-2.18,1.142,8.366,8.366,0,0,1-2.56.4,6.9,6.9,0,0,1-3.339-.8,5.974,5.974,0,0,1-2.336-2.223,6.467,6.467,0,0,1,0-6.4,5.963,5.963,0,0,1,2.344-2.223,6.992,6.992,0,0,1,3.365-.8,7.345,7.345,0,0,1,2.768.5,5.433,5.433,0,0,1,2.094,1.471l-1.419,1.384a4.551,4.551,0,0,0-3.339-1.384,4.715,4.715,0,0,0-2.275.536,3.913,3.913,0,0,0-1.557,1.506,4.334,4.334,0,0,0-.563,2.214,4.266,4.266,0,0,0,.563,2.18,4.032,4.032,0,0,0,1.557,1.523,4.56,4.56,0,0,0,2.257.554,4.877,4.877,0,0,0,2.493-.623Z" transform="translate(-2558.538 -708.961)" fill="#e8e8e8"/>
      </g>
      <g id="Groupe_80" data-name="Groupe 80" transform="translate(13.253 3.495)">
        <path id="Tracé_92" data-name="Tracé 92" d="M2687.552,745.923a4.419,4.419,0,0,1-1.756-1.687,5.049,5.049,0,0,1,0-4.888,4.441,4.441,0,0,1,1.756-1.678,5.608,5.608,0,0,1,5.069,0,4.441,4.441,0,0,1,1.757,1.678,5.052,5.052,0,0,1,0,4.888,4.418,4.418,0,0,1-1.757,1.687,5.606,5.606,0,0,1-5.069,0Zm4.5-2.042a3.2,3.2,0,0,0,0-4.187,2.641,2.641,0,0,0-1.972-.8,2.613,2.613,0,0,0-1.964.8,3.232,3.232,0,0,0,0,4.187,2.613,2.613,0,0,0,1.964.8A2.641,2.641,0,0,0,2692.051,743.882Z" transform="translate(-2685.165 -737.065)" fill="#e8e8e8"/>
      </g>
      <g id="Groupe_81" data-name="Groupe 81" transform="translate(24.412 3.495)">
        <path id="Tracé_93" data-name="Tracé 93" d="M2794.178,745.923a4.408,4.408,0,0,1-1.756-1.687,5.039,5.039,0,0,1,0-4.888,4.43,4.43,0,0,1,1.756-1.678,5.609,5.609,0,0,1,5.07,0,4.423,4.423,0,0,1,1.755,1.678,5.027,5.027,0,0,1,0,4.888,4.4,4.4,0,0,1-1.755,1.687,5.607,5.607,0,0,1-5.07,0Zm4.5-2.042a3.2,3.2,0,0,0,0-4.187,2.644,2.644,0,0,0-1.973-.8,2.615,2.615,0,0,0-1.963.8,3.228,3.228,0,0,0,0,4.187,2.615,2.615,0,0,0,1.963.8A2.643,2.643,0,0,0,2798.677,743.882Z" transform="translate(-2791.79 -737.065)" fill="#e8e8e8"/>
      </g>
      <g id="Groupe_82" data-name="Groupe 82" transform="translate(35.572 3.495)">
        <path id="Tracé_94" data-name="Tracé 94" d="M2908.346,737.169v7.838q0,4.879-4.984,4.879a9.246,9.246,0,0,1-2.525-.337,5.367,5.367,0,0,1-1.973-.977l.969-1.626a4.641,4.641,0,0,0,1.531.8,6,6,0,0,0,1.877.3,3.1,3.1,0,0,0,2.232-.692,2.828,2.828,0,0,0,.71-2.111v-.485a3.558,3.558,0,0,1-1.35.917,4.753,4.753,0,0,1-1.747.311,5.011,5.011,0,0,1-2.379-.562,4.227,4.227,0,0,1-1.678-1.583,4.448,4.448,0,0,1-.615-2.336,4.4,4.4,0,0,1,.615-2.327,4.187,4.187,0,0,1,1.678-1.566,5.076,5.076,0,0,1,2.379-.554,4.882,4.882,0,0,1,1.825.329,3.373,3.373,0,0,1,1.375,1v-1.228Zm-2.916,6.255a2.5,2.5,0,0,0,.787-1.912,2.449,2.449,0,0,0-.787-1.886,3.184,3.184,0,0,0-4.049,0,2.448,2.448,0,0,0-.786,1.886,2.5,2.5,0,0,0,.786,1.912,3.155,3.155,0,0,0,4.049,0Z" transform="translate(-2898.414 -737.065)" fill="#e8e8e8"/>
      </g>
      <g id="Groupe_83" data-name="Groupe 83" transform="translate(48.34)">
        <path id="Tracé_95" data-name="Tracé 95" d="M3020.411,703.671h2.163v12.838h-2.163Z" transform="translate(-3020.411 -703.671)" fill="#e8e8e8"/>
      </g>
      <g id="Groupe_84" data-name="Groupe 84" transform="translate(52.579 3.495)">
        <path id="Tracé_96" data-name="Tracé 96" d="M3070.323,742.48h-7.249a2.515,2.515,0,0,0,.995,1.617,3.259,3.259,0,0,0,2,.6,3.371,3.371,0,0,0,2.51-1l1.159,1.332a3.91,3.91,0,0,1-1.574,1.125,6.237,6.237,0,0,1-4.827-.225,4.358,4.358,0,0,1-1.792-1.687,4.764,4.764,0,0,1-.631-2.448,4.835,4.835,0,0,1,.614-2.431,4.361,4.361,0,0,1,1.706-1.687,4.963,4.963,0,0,1,2.456-.605,4.856,4.856,0,0,1,2.415.6,4.234,4.234,0,0,1,1.66,1.678,5.079,5.079,0,0,1,.6,2.5Q3070.358,742.065,3070.323,742.48Zm-6.393-3.071a2.578,2.578,0,0,0-.873,1.635h5.241a2.546,2.546,0,0,0-.848-1.626,2.578,2.578,0,0,0-1.765-.623A2.609,2.609,0,0,0,3063.93,739.409Z" transform="translate(-3060.911 -737.065)" fill="#e8e8e8"/>
      </g>
      <g id="Groupe_85" data-name="Groupe 85" transform="translate(69.084 0.727)">
        <path id="Tracé_97" data-name="Tracé 97" d="M3226.34,711.134a3.986,3.986,0,0,1,1.792,1.488,4.545,4.545,0,0,1,0,4.594,3.965,3.965,0,0,1-1.792,1.5,6.7,6.7,0,0,1-2.742.519h-2.733v3.495h-2.25V710.615h4.982A6.7,6.7,0,0,1,3226.34,711.134Zm-.613,5.571a2.466,2.466,0,0,0,0-3.564,3.455,3.455,0,0,0-2.231-.623h-2.63v4.81h2.63A3.456,3.456,0,0,0,3225.727,716.705Z" transform="translate(-3218.616 -710.615)" fill="#e8e8e8"/>
      </g>
      <g id="Groupe_86" data-name="Groupe 86" transform="translate(81.438)">
        <path id="Tracé_98" data-name="Tracé 98" d="M3336.647,703.671h2.163v12.838h-2.163Z" transform="translate(-3336.647 -703.671)" fill="#e8e8e8"/>
      </g>
      <g id="Groupe_87" data-name="Groupe 87" transform="translate(85.78 3.495)">
        <path id="Tracé_99" data-name="Tracé 99" d="M3385.45,738.042a3.78,3.78,0,0,1,1.081,2.95v5.416h-2.042v-1.125a2.518,2.518,0,0,1-1.134.926,4.432,4.432,0,0,1-1.772.32,4.352,4.352,0,0,1-1.818-.355,2.815,2.815,0,0,1-1.2-.986,2.5,2.5,0,0,1-.424-1.427,2.448,2.448,0,0,1,.926-2,4.6,4.6,0,0,1,2.915-.753h2.388v-.138a1.893,1.893,0,0,0-.579-1.488,2.507,2.507,0,0,0-1.721-.519,4.97,4.97,0,0,0-1.531.242,3.774,3.774,0,0,0-1.272.675l-.847-1.574a5.247,5.247,0,0,1,1.746-.848,7.78,7.78,0,0,1,2.163-.294A4.51,4.51,0,0,1,3385.45,738.042Zm-1.973,6.54a2.028,2.028,0,0,0,.89-1.064v-1.073h-2.232q-1.869,0-1.869,1.229a1.1,1.1,0,0,0,.467.934,2.139,2.139,0,0,0,1.3.346A2.793,2.793,0,0,0,3383.477,744.583Z" transform="translate(-3378.139 -737.065)" fill="#e8e8e8"/>
      </g>
      <g id="Groupe_88" data-name="Groupe 88" transform="translate(95.054 3.599)">
        <path id="Tracé_100" data-name="Tracé 100" d="M3477.11,738.056l-4.325,9.983a4.671,4.671,0,0,1-1.471,2.119,3.528,3.528,0,0,1-2.094.614,4.307,4.307,0,0,1-1.367-.225,3.015,3.015,0,0,1-1.107-.623l.865-1.592a2.345,2.345,0,0,0,.735.467,2.247,2.247,0,0,0,.856.173,1.478,1.478,0,0,0,.944-.294,2.615,2.615,0,0,0,.683-.986l.156-.363-4.031-9.274h2.248l2.906,6.834,2.925-6.834Z" transform="translate(-3466.746 -738.056)" fill="#e8e8e8"/>
      </g>
    </g>
  </g>
  <g id="Groupe_99" data-name="Groupe 99" transform="translate(8.548 7.807)">
    <g id="Groupe_92" data-name="Groupe 92" transform="translate(17.575 11.448)">
      <g id="Groupe_91" data-name="Groupe 91">
        <path id="Tracé_101" data-name="Tracé 101" d="M2428.186,686.652l-5.813-3.355-4.782,4.622,4.37,4.225,6.226-3.594a1.1,1.1,0,0,0,0-1.9Z" transform="translate(-2417.59 -683.297)" fill="#e8e8e8"/>
      </g>
    </g>
    <g id="Groupe_94" data-name="Groupe 94" transform="translate(0.951)">
      <g id="Groupe_93" data-name="Groupe 93">
        <path id="Tracé_102" data-name="Tracé 102" d="M2279.171,584.792l-6.4-3.693-14.021-7.185,15.837,15.308Z" transform="translate(-2258.752 -573.914)" fill="#e8e8e8"/>
      </g>
    </g>
    <g id="Groupe_96" data-name="Groupe 96" transform="translate(1.183 16.832)">
      <g id="Groupe_95" data-name="Groupe 95">
        <path id="Tracé_103" data-name="Tracé 103" d="M2260.975,749.825l13.812-7.609,5.962-3.442-4.171-4.032Z" transform="translate(-2260.975 -734.742)" fill="#e8e8e8"/>
      </g>
    </g>
    <g id="Groupe_98" data-name="Groupe 98" transform="translate(0 0.619)">
      <g id="Groupe_97" data-name="Groupe 97">
        <path id="Tracé_104" data-name="Tracé 104" d="M2249.685,579.832l-.015,30.916,16-15.466Z" transform="translate(-2249.67 -579.832)" fill="#e8e8e8"/>
      </g>
    </g>
  </g>
  <text id="Download_on_the" data-name="Download on the" transform="translate(41.287 14.219)" fill="#cbcbcb" font-size="8" font-family="CenturyGothic, Century Gothic"><tspan x="0" y="0">Download on the</tspan></text>
</svg>
  )
}

export default Googleplay