import React from 'react'
import styled from 'styled-components'
import Brackets from '../../images/tournamentWinnersImage/Brackets.png'
import Rank from '../../images/tournamentWinnersImage/Rank.png'
import { motion } from "framer-motion";

const Container = styled.div`
position: relative;
width: 50%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
@media screen and (max-width: 1200px) {
width: 100%;
height: auto;
margin-top: 30px;
}
`
const ImagesContainer = styled.div`
position: relative;
width: 100%;
height: 80%;
display: flex;
flex-direction: column;
gap: 20px;
max-width: 500px;
display:flex;
align-items:center;
justify-content:center;
img{
  max-width:100%;
}
@media screen and (max-width: 1400px) {
   img{
  max-width: 80% !important;
  height: auto !important;
  }
}



@media screen and (max-width: 1200px) {
  max-width: 90%;
}
@media screen and (max-width: 500px) {
  display: flex;
  align-items: center;
  justify-content: center;
  img{
  max-width: 90% !important;
  height: auto !important;
  }

}

`
const MiddleSectionFirstSide = (props) => {
  const [startAnimation,setStartAnimation]=React.useState(false)
  const pageHeaderPosetion=React.useRef()

  React.useEffect(()=>{
    let divTopPosetionInDocument=pageHeaderPosetion.current.offsetTop+document.getElementById('Home').offsetHeight
    let divheight=pageHeaderPosetion.current.offsetHeight
    let clinetHeight=props.scrolloffsetHeight
    if(props.scrollPosetion+clinetHeight>divTopPosetionInDocument+(divheight/2) ){
        setStartAnimation(true)
    }else if(props.scrollPosetion+clinetHeight<divTopPosetionInDocument+(divheight/2) ){
        setStartAnimation(false)
    }
   },[props.scrollPosetion])

   const cardVariants = {
     offscreen: {
       opacity: 0,
      y: "100%",

    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8
      }
    }
  };

  return (
    <Container ref={pageHeaderPosetion}>
        <ImagesContainer as={motion.div} 
              className="card-container"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
              >
            <motion.img src={Rank} variants={cardVariants}/>
            <motion.img src={Brackets} variants={cardVariants}/>
        </ImagesContainer>
    </Container>
  )
}

export default MiddleSectionFirstSide