import React from 'react'
import styled from 'styled-components'

import axios from 'axios'
import leftarrow from '../../icons/leftarrow.svg'
import rightarrow from '../../icons/rightarrow.svg'
import { useNavigate  } from "react-router-dom";


import LOL from './LOL.png'
import free2 from './free2.png'
import call from './call.png'
import Free from './Free.png'
 



const Container = styled.div`
position: relative;
height: 100%;
width: 50%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
z-index: 15616165;
min-height: 100%;

@media screen and (max-width: 700px) {
width: 100%;
height: 100%;
flex-direction: row;
overflow-y: scroll;
justify-content: flex-start;
padding-left: 5vw;
padding-right: 5vw;
gap: 50px;

}
`








const ArrowRight = styled.div`
position: absolute;
width: 10%;
right: 0%;
top: 50%;
transform: translateY(-50%);
display: flex;
align-items: center;
justify-content: center;

img{
    cursor: pointer;
    max-width: 25%;
    min-width: 30px;
}


`
const Wrapper = styled.div`
position: relative;
top: 45%;
left: 45%;
transform: translate(-50%,-50%);
height: 90%;
width: 90%;

`
const FirstSaction = (props) => {
    const [selectedCart, setSelectedCart] = React.useState(0)
    const [refrech,setrefrech]=React.useState(false)
    const [texts, setText] = React.useState([
        {
          title:'Nilah, the Joy Unbound',
          text:' Nilah wields an illuminated whip to hit its target from a close range. Her whip allows her to get closer to her opponents quicker by pulling her towards them. Such ability allows her to overpower other champions that rely on range. She is also able to to use a whirlpool-like attack that hits nearby enemies'      
        },
        {
          title:"Pearl: The underwater city",
            text: `
                  The newest Valorant season, Episode 5: Dimension, is approaching and along with it emerges the new underwater map: Pearl. The new map will be released on June 22nd. Pearl will be the first map added since last year and will not include any teleporters, ropes or doors
            ` 
        },
        {
          title:"Vanguard Season 3",
            text: `Call of Duty Mobile's Vanguard Season Three has now begun and with it came new content for players to consume. Introducing a new map: Mayhem as well as additional features to already existing ones, adding new mods and a a whole lot of new elements for the ranked play feature, players are in for a treat.
            `  
        },
        {
          title:"Free Fire's 5th Anniversary",
            text: `
Free Fire's 5th Anniversary: 
Free Fires celebrates its 5th anniversary through releasing a new calendar, now available for players. The anniversary rewards will include a myriad of new features such as ,Nexterra, a brand new futuristic looking map, a new diamond store and various in-game rewards that players can gather within the upcoming period.
            `  
        }
    ])
    const goRight = () => {



        setSelectedCart(old => {
            if (old === 3) {
                props.setChangeText(0)
                return 0
            } else {
                props.setChangeText(selectedCart + 1)
                return selectedCart + 1
            }
        })
        

    }
    //
    React.useEffect(()=>{
        setTimeout(() => {
            setrefrech(true)
        }, 1000);
    }, [])



    if (window.innerWidth < 700) {
        return (
            <Container id="firstSection">
              <CardOne  text={texts[0].text} title={texts[0].title} selectedCart={selectedCart}/>
              <CardTwo text={texts[1].text}  title={texts[1].title} selectedCart={selectedCart} />
              <CardThree text={texts[2].text}  title={texts[2].title} selectedCart={selectedCart} />
              <CardPhour text={texts[3].text}  title={texts[3].title} selectedCart={selectedCart} />
        </Container>
           )

    } else {
         return (
    <Container id="firstSection" style={((document.getElementById('SecondSection')!== null)&&(window.innerWidth<window.innerHeight))?{height:document.getElementById('SecondSection').offsetHeight}:{}}>
        <Wrapper>
            
              <CardOne   selectedCart={selectedCart}/>
              <CardTwo   selectedCart={selectedCart} />
              <CardThree   selectedCart={selectedCart} />
              <CardPhour   selectedCart={selectedCart} />
             <ArrowRight alt="arrowRight" onClick={goRight}><img src={rightarrow}/></ArrowRight>

        </Wrapper>
    </Container>
  ) 
    }

}

export default FirstSaction

const TextContainer = styled.div`
position: absolute;
bottom: 0;
display: flex;
flex-direction: column;
gap: 10px;
z-index: 1561651;
align-items: center;
justify-content: center;
padding-bottom: 10px;
width: 90%;
left: 50%;
transform: translateX(-50%);
margin-bottom: 15px;
h1{
        color: white;
    font-size: 3rem;
}
p{
    color: white;
        font-size: 1.3rem;
        text-align: center;
        line-height: 1.9rem;
    
}
`


const CardOnecss = styled.div`
        width: 40%;
        height: 80%;
        position: absolute;
        transform:perspective(1000px) translateY(-50%) translateZ(0px) translateX(100%)  rotateY(0deg);
        transition: 1s;
        background-image: url(${LOL});
        background-size: cover;
        background-position: center;
        
        border-radius: 15px;
        z-index: 5;
        top: 50%;

 
        @media screen and (max-width: 700px) {
                    &:after{
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 15px;
        background: linear-gradient(0deg,#3F4996 0%,rgba(255,255,255,0) 100%);
        z-index: 1;

        }
        top: 5%;
        min-width: 80vw !important;
        height: calc(60vw * (6 / 4)) !important;
        transform:none !important;
        position: relative;
        }
`

const CardOne = (props) => {

    var newStyle = ''
    const one = React.useRef()
    if (one.current != undefined) {
        if (props.selectedCart === 1) {
        one.current.style.opacity = 0
        setTimeout(() => {
            one.current.style.opacity = 1
            one.current.style.transform = ' translateY(-50%) perspective(1000px) translateZ(-400px) translateX(30%) rotateY(30deg)'
        }, 1000);
            setTimeout(() => {
            one.current.style.transform = ' translateY(-50%) perspective(1000px) translateZ(-400px) translateX(30%) rotateY(30deg)'
        }, 500);
        one.current.style.transform = ' translateY(-50%) perspective(1000px) translateZ(50px) translateX(100%)  rotateY(0deg)'
        one.current.style.opacity = 0
        one.current.style.zIndex = -1
            
    } else if (props.selectedCart === 2) {
            one.current.style.transform = ' translateY(-50%) perspective(1000px) translateZ(-300px) translateX(50%) rotateY(30deg)' 
            one.current.style.zIndex = 2
    } else if (props.selectedCart === 3) {
                one.current.style.transform = ' translateY(-50%) perspective(1000px) translateZ(-200px) translateX(70%) rotateY(30deg)' 
                one.current.style.zIndex = 3
    } else {
        one.current.style.transform = ' translateY(-50%) perspective(1000px) translateZ(0px) translateX(100%)  rotateY(0deg)';
        one.current.style.zIndex = 5

    }
    }
    const navigate = useNavigate();
    const openArticleInPage = (title) => {
        if(window.innerWidth<1200){
            navigate("news/"+title)
        }
    }
    return <CardOnecss onClick={() => { openArticleInPage(1)}} ref={one} style={{transform:newStyle}}>
        {window.innerWidth < 700 && <TextContainer><h1>{props.title}</h1><p>{props.text.slice(0,150)}</p></TextContainer>}
    </CardOnecss>
}

const CardTwocss = styled.div`
        width: 40%;
        height: 80%;
        position: absolute;
        transform:perspective(1000px) translateY(-50%) translateZ(-200px) translateX(70%) rotateY(30deg);
        transition: 1s;
        background-image: url(${free2});
        background-size: cover;
        border-radius: 15px;
        z-index: 4;
        top: 50%;
                background-position: center;

        @media screen and (max-width: 700px) {
            top: 5%;
        min-width: 80vw !important;
        height: calc(60vw * (6 / 4)) !important;
        transform:none !important;
        position: relative;
                    &:after{
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 15px;
        background: linear-gradient(0deg,#3F4996 0%,rgba(255,255,255,0) 100%);
        z-index: 1;
    }
        }

`

const CardTwo = (props) => {
    
    var newStyle = ''
    const two = React.useRef()
    if (two.current != undefined) {
        
    if (props.selectedCart === 1) {
        two.current.style.transform = 'translateY(-50%) perspective(1000px) translateZ(0px) translateX(100%)  rotateY(0deg)' 
        two.current.style.zIndex = 5

    } else if (props.selectedCart === 2) {
          two.current.style.opacity = 0
        setTimeout(() => {
            two.current.style.opacity = 1
            two.current.style.transform = 'translateY(-50%) perspective(1000px) translateZ(-400px) translateX(30%) rotateY(30deg)'
        }, 1000);
        setTimeout(() => {
            two.current.style.transform = 'translateY(-50%) perspective(1000px) translateZ(-400px) translateX(30%) rotateY(30deg)'
        }, 500);
                    two.current.style.zIndex = -1

        two.current.style.transform = 'translateY(-50%) perspective(1000px) translateZ(50px) translateX(100%)  rotateY(0deg)'
        two.current.style.opacity = 0
    } else if (props.selectedCart === 3) {
        two.current.style.transform = 'translateY(-50%) perspective(1000px) translateZ(-300px) translateX(50%) rotateY(30deg)' 
        two.current.style.zIndex = 2
    } else {
        two.current.style.transform = 'translateY(-50%) perspective(1000px) translateZ(-200px) translateX(70%) rotateY(30deg)' 
        two.current.style.zIndex = 3
    }

}


    const navigate = useNavigate();
    const openArticleInPage = (title) => {
        if(window.innerWidth<1200){
            navigate("news/"+title)
        }    }
    return <CardTwocss onClick={() => { openArticleInPage(2)}} ref={two} style={{transform:newStyle}}>
        {window.innerWidth < 700 && <TextContainer><h1>{props.title}</h1><p>{props.text.slice(0,150)}</p></TextContainer>}

    </CardTwocss>
}
const CardThreecss = styled.div`
    width: 40%;
    height: 80%;
    position: absolute;
    transform:perspective(1000px) translateY(-50%) translateZ(-300px) translateX(50%) rotateY(30deg);
    transition: 1s;
    background-image: url(${call});
    background-size: cover;
    border-radius: 15px;
    z-index: 3;
    top: 50%;
            background-position: center;

        @media screen and (max-width: 700px) {
            top: 5%;
        min-width: 80vw !important;
        height: calc(60vw * (6 / 4)) !important;
        transform:none !important;
        position: relative;
                            &:after{
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 15px;
        background: linear-gradient(0deg,#3F4996 0%,rgba(255,255,255,0) 100%);
        z-index: 1;
    }
        }

`

const CardThree = (props) => {

    var newStyle = ''
    const three = React.useRef()
    if (three.current != undefined) {
        
    if (props.selectedCart === 1) {
        three.current.style.transform = 'translateY(-50%) perspective(1000px) translateZ(-200px) translateX(70%) rotateY(30deg)' 
        three.current.style.zIndex = 3
    } else if (props.selectedCart === 2) {
        three.current.style.transform = 'translateY(-50%) perspective(1000px) translateZ(0px) translateX(100%)  rotateY(0deg)' 
        three.current.style.zIndex = 5

    } else if (props.selectedCart === 3) {
                  three.current.style.opacity = 0
        setTimeout(() => {
            three.current.style.opacity = 1
            three.current.style.transform = 'translateY(-50%) perspective(1000px) translateZ(-400px) translateX(30%) rotateY(30deg)'
        }, 1000);
        setTimeout(() => {
            three.current.style.transform = 'translateY(-50%) perspective(1000px) translateZ(-400px) translateX(30%) rotateY(30deg)'
        }, 500);
                    three.current.style.zIndex = -1

        three.current.style.transform = 'translateY(-50%) perspective(1000px) translateZ(50px) translateX(100%)  rotateY(0deg)'
        three.current.style.opacity = 0
    } else {
        three.current.style.zIndex = 2
        three.current.style.transform = 'translateY(-50%) perspective(1000px) translateZ(-300px) translateX(50%) rotateY(30deg)' 
    }

}

     const navigate = useNavigate();
    const openArticleInPage = (title) => {
        if(window.innerWidth<1200){
            navigate("news/"+title)
        }
    }

    return <CardThreecss onClick={() => { openArticleInPage(3)}} ref={three} style={{transform:newStyle}}>
        {window.innerWidth < 700 && <TextContainer><h1>{props.title}</h1><p>{props.text.slice(0,150)}</p></TextContainer>}

    </CardThreecss>
}
const CardPhourcss = styled.div`
    width: 40%;
    height: 80%;
    position: absolute;
    transform:perspective(1000px) translateY(-50%) translateZ(-400px) translateX(30%) rotateY(30deg);
    transition: 1s;
    background-image: url(${Free});
    background-size: cover;
    border-radius: 15px;
    z-index: 2;
    top: 50%;
            background-position: center;

        @media screen and (max-width: 700px) {
            top: 5%;
        min-width: 80vw !important;
        height: calc(60vw * (6 / 4)) !important;
        transform:none !important;
        position: relative;
                    &:after{
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 15px;
        background: linear-gradient(0deg,#3F4996 0%,rgba(255,255,255,0) 100%);
        z-index: 1;
    }
        }

`

const CardPhour = (props) => {
    var newStyle = ''
    const phour = React.useRef()
    React.useEffect(()=>{
        if (phour.current != undefined) {
            if (props.selectedCart === 1) {
                phour.current.style.transform = 'translateY(-50%) perspective(1000px) translateZ(-300px) translateX(50%) rotateY(30deg)'
                phour.current.style.zIndex = 2
            } else if (props.selectedCart === 2) {
                phour.current.style.transform = 'translateY(-50%) perspective(1000px) translateZ(-200px) translateX(70%) rotateY(30deg)'
                phour.current.style.zIndex = 3
    
            } else if (props.selectedCart === 3) {
                phour.current.style.transform = 'translateY(-50%) perspective(1000px) translateZ(0px) translateX(100%)  rotateY(0deg)'
                phour.current.style.zIndex = 5
            } else {
                phour.current.style.opacity = 0
                setTimeout(() => {
                    phour.current.style.opacity = 1
                    phour.current.style.transform = 'translateY(-50%) perspective(1000px) translateZ(-400px) translateX(30%) rotateY(30deg)'
                }, 1000);
    
                setTimeout(() => {
                        phour.current.style.transform = 'translateY(-50%) perspective(1000px) translateZ(-400px) translateX(30%) rotateY(30deg)'
                }, 500);
                                phour.current.style.zIndex = -1
    
                phour.current.style.transform = 'translateY(-50%) perspective(1000px) translateZ(50px) translateX(100%)  rotateY(0deg)'
                phour.current.style.opacity = 0
            }
        }
    },[props.selectedCart])

    
    const navigate = useNavigate();
    const openArticleInPage = (title) => {
        if(window.innerWidth<1200){
            navigate("news/"+title)
        }    }
    return <CardPhourcss onClick={() => { openArticleInPage(4)}} ref={phour}>
        {window.innerWidth < 700 && <TextContainer><h1>{props.title}</h1><p>{props.text!=undefined&& props.text.slice(0,150)}</p></TextContainer>}
    </CardPhourcss>
}