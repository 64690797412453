import React from 'react'
import styled from 'styled-components'
import backArrow from '../../icons/Backarrow.svg'
import { useNavigate  } from "react-router-dom";
import backprivecyandterms from '../../images/backprivecyandterms.png'

const Container = styled.div`
position: relative;
width: 100%;
height: auto;
background-image: url(${backprivecyandterms});
background-size: cover;
padding-bottom: 30px;

`
const Header = styled.div`
position: relative;
height: auto;
width: 90%;
left: 50%;
transform: translateX(-50%);
padding: 30px;
box-sizing: border-box;
cursor: pointer;
`
const TextContainer = styled.div`
position: relative;
height: auto;
width: 90%;
left: 50%;
transform: translateX(-50%);
padding: 30px;
box-sizing: border-box;
h1{
  font-size: 2rem;
  color: white;
}
h2{
  font-size: 1.7rem;
  color: #fe4b57;
}
p{
  font-size: 1.5rem;
    line-height: 1.5;
  color: white;
  color: #a0a5c7;
}
ul,li{
  font-size: 1.3rem;
    line-height: 1.6;
  color: white;
    color: #a0a5c7;

}
&:after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 30px;
  inset: 0px;
    mix-blend-mode: overlay;

 background-color: #FFFFFF ;
 opacity: 0.05;
   background-image: linear-gradient(to bottom, #fff 0%, #181b31 215%);

}
`
const Index = () => {
  React.useEffect(() => {
    document.querySelector("#root").scrollTop=0
  },[])
    const navigate = useNavigate();
    const goTo = (route) => {
            navigate("/")
    }

  return (
    <Container>
      <Header>
        <img onClick={() => goTo('Termsandconditions')} src={backArrow}/>
      </Header>
      <TextContainer>
        <h1>TERMS OF USE</h1>
        <p style={{marginTop:"30px"}}>
          Last updated June 24, 2022
        </p>
        <h2 style={{marginTop:"30px"}}>AGREEMENT TO TERMS</h2>
        

        <p style={{marginTop:"20px"}}>These Terms of Use constitute a legally binding agreement made between you, whether
        personally or on behalf of an entity (“you”) and T7D GAMING L.L.C , hereinafter referred to as
        (&quot;T7D Gaming&quot;, “we”, “us”, or “our”),
        These Terms concerns your access to and use of the App as well as any other media form,
        media channel, mobile website or mobile application related, linked, or otherwise connected
        thereto (collectively, the “App”). You agree that by accessing the App, you have read,
        understood, and agreed to be bound by all these Terms of Use. IF YOU DO NOT AGREE WITH
        ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
        APP AND YOU MUST DISCONTINUE USE IMMEDIATELY.
        Supplemental terms and conditions or documents that may be posted on the App from time to
        time are hereby expressly incorporated herein by reference , these supplemental terms and
        conditions concerns generally Tournaments’ Rules specific to some games and tournaments .
        We reserve the right, in our sole discretion, to make changes or modifications to these Terms of
        Use at any time and for any reason. We will alert you about any changes by updating the “Last
        updated” date of these Terms of Use, and you waive any right to receive specific notice of each
        such change. It is your responsibility to periodically review these Terms of Use to stay informed
        of updates. You will be subject to, and will be deemed to have been made aware of and to have
        accepted, the changes in any revised Terms of Use by your continued use of the App after the
        date such revised Terms of Use are posted.
        The information provided on the App is not intended for distribution to or use by any person or
        entity in any jurisdiction or country where such distribution or use would be contrary to law or
        regulation or which would subject us to any registration requirement within such jurisdiction or
        country. Accordingly, those persons who choose to access the App from other locations do so

        on their own initiative and are solely responsible for compliance with local laws, if and to the
        extent local laws are applicable.
        The App is intended for users who are at least 13 years of age. All users who are minors in the
        jurisdiction in which they reside (generally under the age of 18) must have the permission of,
        and be directly supervised by, their parent or guardian to use the App If you are a minor, you
        must have your parent or guardian read and agree to these Terms of Use prior to you using the
        App.
        </p>

        
        <h2 style={{marginTop:"30px"}}>INTELLECTUAL PROPERTY RIGHTS</h2>

        <p style={{marginTop:"20px"}}>
        Unless otherwise indicated, the App is our proprietary property and all source code, databases,
        functionality, software, website designs, audio, video, text, photographs, and graphics on the
        App (collectively, the “Content”) and the trademarks, service marks, and logos contained therein
        (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright
        and trademark laws and various other intellectual property rights and unfair competition laws of
        the UAE , international copyright laws, and international conventions. The Content and the
        Marks are provided on the App “AS IS” for your information and personal use only. Except as
        expressly provided in these Terms of Use, no part of the App and no Content or Marks may be
        copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded,
        translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial
        purpose whatsoever, without our express prior written permission.
        Provided that you are eligible to use the App, you are granted a limited license to access and
        use the App and to download or print a copy of any portion of the Content to which you have
        properly gained access solely for your personal, non-commercial use. We reserve all rights not
        expressly granted to you in and to the App, the Content and the Marks.
        </p>


        <h2 style={{marginTop:"30px"}}>INTELLECTUAL PROPERTY RIGHTS</h2>

        <p style={{marginTop:"20px"}}>
          By using the App, you represent and warrant that: (1) all registration information you submit will
          be true, accurate, current, and complete; (2) you will maintain the accuracy of such information

          and promptly update such registration information as necessary; (3) you have the legal capacity
          and you agree to comply with these Terms of Use; (4) you are not under the age of 13; (5) you
          are not a minor in the jurisdiction in which you reside, or if a minor, you have received parental
          permission to use the App; (6) you will not access the App through automated or non-human
          means, whether through a bot, script, or otherwise; (7) you will not use the App for any illegal or
          unauthorized purpose; and (8) your use of the App will not violate any applicable law or
          regulation.
          If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the
          right to suspend or terminate your account and refuse all current or future use of the App (or
          any portion thereof).
        </p>

        <h2 style={{marginTop:"30px"}}>USER REPRESENTATIONS</h2>

        <p style={{marginTop:"20px"}}>
          By using the App, you represent and warrant that: (1) all registration information you submit will
          be true, accurate, current, and complete; (2) you will maintain the accuracy of such information

          and promptly update such registration information as necessary; (3) you have the legal capacity
          and you agree to comply with these Terms of Use; (4) you are not under the age of 13; (5) you
          are not a minor in the jurisdiction in which you reside, or if a minor, you have received parental
          permission to use the App; (6) you will not access the App through automated or non-human
          means, whether through a bot, script, or otherwise; (7) you will not use the App for any illegal or
          unauthorized purpose; and (8) your use of the App will not violate any applicable law or
          regulation.
          If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the
          right to suspend or terminate your account and refuse all current or future use of the App (or
          any portion thereof).
        </p>

        <h2 style={{marginTop:"30px"}}>USER REGISTRATION</h2>
        <p style={{marginTop:"20px"}}>
          You may be required to register with the App. You agree to keep your password confidential and
          will be responsible for all use of your account and password. We reserve the right to remove,
          reclaim, or change a username you select if we determine, in our sole discretion, that such
          username is inappropriate, obscene, or otherwise objectionable.
        </p>
        <h2 style={{marginTop:"30px"}}>MARKETPLACE OFFERINGS</h2>

        <p style={{marginTop:"20px"}}>
          All Marketplace Offerings Available on the App are subject to availability, and we cannot
          guarantee that Marketplace Offerings will be in stock. Certain Marketplace Offerings may be
          available exclusively online through the App. Such Marketplace Offerings may have limited
          quantities and are subject to return or exchange only according to our Return Policy.

          We reserve the right to limit the quantities of the Marketplace Offerings offered or available on
          the App. All descriptions or pricing of the Marketplace Offerings are subject to change at any
          time without notice, at our sole discretion. We reserve the right to discontinue any Marketplace
          Offerings at any time for any reason. We do not warrant that the quality of any of the
          Marketplace Offerings purchased by you will meet your expectations or that any errors in the
          App will be corrected.
        </p>

        <h2 style={{marginTop:"30px"}}>PURCHASES AND PAYMENT</h2>
        <p style={{ marginTop: "20px" }}>
            We accept several forms of payment that will be displayed on the App according to your
            region/country.
            You agree to provide current, complete, and accurate purchase and account information for all
            purchases of the Marketplace Offerings made via the App. You further agree to promptly update
            account and payment information, including email address, payment method, and payment card
            expiration date, so that we can complete your transactions and contact you as needed. Sales
            tax will be added to the price of purchases as deemed required by us. We may change prices at
            any time. All payments shall be in your region/country’s Currency.
            You agree to pay all charges at the prices then in effect for your purchases, and you authorize
            us to charge your chosen payment provider for any such amounts upon placing your order. We
            reserve the right to correct any errors or mistakes in pricing, even if we have already requested
            or received payment.
            We reserve the right to refuse any order placed through the App. We may, in our sole discretion,
            limit or cancel quantities purchased per person, per household, or per order. These restrictions
            may include orders placed by or under the same customer account, the same payment method,
            and/or orders that use the same billing or shipping address. We reserve the right to limit or
            prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers, or
            distributors.
        </p>

        <h2 style={{marginTop:"30px"}}>REFUND POLICY </h2>
        <p style={{marginTop:"20px"}}>
            All sales are final and no refund will be issued unless proven that the digital good received is
            damaged or unfunctional from the source and not misused by the user .
            We decide on that at our own discretion as we have our own verification and tracking methods ;
            In order to exercise Your right of refund, You must inform us of your request by means of a clear
            statement By email: Contact@t7dgaming.com
            We will reimburse You no later than 14 days from the day on which We confirm your request .
            We will reimburse you with the equivalent digital good or use the same means of payment as
            you used for the Order, and You will not incur any fees for such reimbursement.
        </p>

        <h2 style={{marginTop:"30px"}}>PROHIBITED ACTIVITIES</h2>

        <p style={{marginTop:"20px"}}>
          You may not access or use the App for any purpose other than that for which we make the App
          available. The App may not be used in connection with any commercial endeavors except those
          that are specifically endorsed or approved by us.
          As a user of the App, you agree not to:
        </p>
          <ul>
          <li>
            1. Systematically retrieve data or other content from the App to create or compile,
            directly or indirectly, a collection, compilation, database, or directory without written
            permission from us.
          </li>
          <li>
            2. Trick, defraud, or mislead us and other users, especially in any attempt to learn
            sensitive account information such as user passwords.
          </li>
          <li>
            3. Circumvent, disable, or otherwise interfere with security-related features of the App,
including features that prevent or restrict the use or copying of any Content or enforce
limitations on the use of the App and/or the Content contained therein.
          </li>
          <li>4. Disparage, tarnish, or otherwise harm, in our opinion, us and/or the App.</li>
          <li>5. Use any information obtained from the App in order to harass, abuse, or harm another
person.</li>
          <li>6. Make improper use of our support services or submit false reports of abuse , cheating
or misconduct.</li>
          <li>7. Use the App in a manner inconsistent with any applicable laws or regulations.</li>
          <li>8. Use App to advertise or offer to sell goods and services, in this matter, we insist to
remind you that the purchase of the products offered in our online shop for the purpose
of their resale in the regulated or the black market is extremely prohibited and will
expose you to serious legal proceedings ;</li>
          <li>9. Engage in unauthorized framing of or linking to the App.</li>
          <li>10. Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or
other material, including excessive use of capital letters and spamming (continuous
posting of repetitive text), that interferes with any party’s uninterrupted use and
enjoyment of the App or modifies, impairs, disrupts, alters, or interferes with the use,
features, functions, operation, or maintenance of the App.</li>
          <li>11. Engage in any automated use of the system, such as using scripts to send
comments or messages, or using any data mining, robots, or similar data gathering and
extraction tools.</li>
          <li>12. Delete the copyright or other proprietary rights notice from any Content.</li>
          <li>13. Attempt to impersonate another user or person or use the username of another user.</li>
          <li>14. Sell or otherwise transfer your profile.</li>
          <li>15. Upload or transmit (or attempt to upload or to transmit) any material that acts as a
passive or active information collection or transmission mechanism, including without
limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or
other similar devices (sometimes referred to as “spyware” or “passive collection
mechanisms” or “pcms”).</li>
          <li>16. Interfere with, disrupt, or create an undue burden on the App or the networks or
services connected to the App.</li>
          <li>17. Harass, annoy, intimidate, or threaten any of our employees or agents engaged in
providing any portion of the App to you.</li>
          <li>18. Attempt to bypass any measures of the App designed to prevent or restrict access to
the App, or any portion of the App .</li>
          <li>19. Copy or adapt the App’s software, including but not limited to Flash, PHP, HTML,
JavaScript, or other code.</li>
          <li>20. Decipher, decompile, disassemble, or reverse engineer any of the software
comprising or in any way making up a part of the App.</li>
          <li>21. Except as may be the result of standard search engine or Internet browser usage,
use, launch, develop, or distribute any automated system, including without limitation,
any spider, robot, cheat utility, scraper, or offline reader that accesses App, or using or
launching any unauthorized script or other software.</li>
          <li>22. Use a buying agent or purchasing agent to make purchases on App.</li>
          <li>23. Make any unauthorized use of the App, including collecting usernames and/or email
addresses of users by electronic or other means for the purpose of sending unsolicited
email, or creating user accounts by automated means or under false pretenses.</li>
          <li>24. Use the App as part of any effort to compete with us or otherwise use the App and/or
the Content for any revenue-generating endeavor or commercial enterprise.</li>
          </ul>
        <h2 style={{marginTop:"30px"}}>USER GENERATED CONTRIBUTIONS</h2>

        <p style={{marginTop:"20px"}}>
        The App may invite you to chat, contribute to, or participate in blogs, message boards, online
        forums, Discord and other functionality, and may provide you with the opportunity to create,
        submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials
        to us or on the App, including but not limited to text, writings, video, audio, photographs,
        graphics, comments, suggestions, or personal information or other material (collectively,
        &quot;Contributions&quot;). Contributions may be viewable by other users of App and through third-party
        websites. As such, any Contributions you transmit may be treated as non-confidential and non-
        proprietary. When you create or make available any Contributions, you thereby represent and
        warrant that:
        </p>
        <ul>
          <li>1. The creation, distribution, transmission, public display, or performance, and the accessing,
downloading, or copying of your Contributions do not and will not infringe the proprietary rights,
including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any
third party.</li>
          <li>
            2. You are the creator and owner of or have the necessary licenses, rights, consents, releases,
and permissions to use and to authorize us, the App, and other users of the App to use your
Contributions in any manner contemplated by the App and these Terms of Use.
          </li>
          <li>
            3. You have the written consent, release, and/or permission of each and every identifiable
individual person in your Contributions to use the name or likeness of each and every such
identifiable individual person to enable inclusion and use of your Contributions in any manner
contemplated by the App and these Terms of Use.
          </li>
          <li>4. Your Contributions are not false, inaccurate, or misleading.</li>
          <li>5. Your Contributions are not unsolicited or unauthorized advertising, promotional materials,
pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.</li>
          <li>6. Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous,
slanderous, or otherwise objectionable (as determined by us).</li>
          <li>7. Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</li>
          <li>8. Your Contributions are not used to harass or threaten (in the legal sense of those terms) any
other person and to promote violence against a specific person or class of people.</li>
          <li>9. Your Contributions do not violate any applicable law, regulation, or rule.</li>
          <li>10. Your Contributions do not violate the privacy or publicity rights of any third party.</li>
          <li>11. Your Contributions do not contain any material that solicits personal information from
anyone under the age of 18 or exploits people under the age of 18 in a sexual or violent manner.</li>
          <li>12. Your Contributions do not violate any applicable law concerning child pornography, or
otherwise intended to protect the health or well-being of minors.</li>
          <li>13. Your Contributions do not include any offensive comments that are connected to race,
national origin, gender, sexual preference, or physical handicap.</li>
          <li>14. Your Contributions do not otherwise violate, or link to material that violates, any provision of
these Terms of Use, or any applicable law or regulation.</li>
      
        </ul>
        <p style={{marginTop:"20px"}}>Any use of the App in violation of the foregoing violates these Terms of Use and may result in,
among other things, termination or suspension of your rights to use the App.</p>
        <h2 style={{marginTop:"30px"}}>CONTRIBUTION LICENSE</h2>

        <p style={{marginTop:"20px"}}>
By posting your Contributions to any part of the App or making Contributions accessible to the
Appb y linking your account from the App to any of your social networking accounts, you
automatically grant, and you represent and warrant that you have the right to grant, to us an
unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid,
worldwide right, and license to host, use, copy, reproduce, disclose, sell, resell, publish,
broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat, translate,
transmit, excerpt (in whole or in part), and distribute such Contributions (including, without
limitation, your image and voice) for any purpose, commercial, advertising, or otherwise, and to
prepare derivative works of, or incorporate into other works, such Contributions, and grant and
authorize sublicenses of the foregoing. The use and distribution may occur in any media
formats and through any media channels.
This license will apply to any form, media, or technology now known or hereafter developed, and
includes our use of your name, company name, and franchise name, as applicable, and any of
the trademarks, service marks, trade names, logos, and personal and commercial images you
provide. You waive all moral rights in your Contributions, and you warrant that moral rights have
not otherwise been asserted in your Contributions.
We do not assert any ownership over your Contributions. You retain full ownership of all of your
Contributions and any intellectual property rights or other proprietary rights associated with
your Contributions. We are not liable for any statements or representations in your Contributions
provided by you in any area on the App. You are solely responsible for your Contributions to the

App and you expressly agree to exonerate us from any and all responsibility and to refrain from
any legal action against us regarding your Contributions.
We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change
any Contributions; (2) to re-categorize any Contributions to place them in more appropriate
locations on the App; and (3) to pre-screen or delete any Contributions at any time and for any
reason, without notice. We have no obligation to monitor your Contributions.
        </p>


                <h2 style={{marginTop:"30px"}}>GUIDELINES FOR REVIEWS</h2>

        <p style={{marginTop:"20px"}}>
We may provide you areas on the App to leave reviews or ratings. When posting a review, you
must comply with the following criteria: (1) you should have firsthand experience with the
person/entity being reviewed; (2) your reviews should not contain offensive profanity, or
abusive, racist, offensive, or hate language; (3) your reviews should not contain discriminatory
references based on religion, race, gender, national origin, age, marital status, sexual orientation,
or disability; (4) your reviews should not contain references to illegal activity; (5) you should not
be affiliated with competitors if posting negative reviews; (6) you should not make any
conclusions as to the legality of conduct; (7) you may not post any false or misleading
statements; and (8) you may not organize a campaign encouraging others to post reviews,
whether positive or negative.
We may accept, reject, or remove reviews in our sole discretion. We have absolutely no
obligation to screen reviews or to delete reviews, even if anyone considers reviews
objectionable or inaccurate. Reviews are not endorsed by us, and do not necessarily represent
our opinions or the views of any of our affiliates or partners. We do not assume liability for any
review or for any claims, liabilities, or losses resulting from any review. By posting a review, you
hereby grant to us a perpetual, non-exclusive, worldwide, royalty-free, fully-paid, assignable, and
sublicensable right and license to reproduce, modify, translate, transmit by any means, display,
perform, and/or distribute all content relating to reviews.
        </p>



                <h2 style={{marginTop:"30px"}}>SOCIAL MEDIA</h2>

        <p style={{marginTop:"20px"}}>
As part of the functionality of the App, you may link your account with online accounts you have
with third-party service providers (each such account, a “Third-Party Account”) by either: (1)
providing your Third-Party Account login information through the App or (2) allowing us to
access your Third-Party Account, as is permitted under the applicable terms and conditions that
govern your use of each Third-Party Account. You represent and warrant that you are entitled to
disclose your Third-Party Account login information to us and/or grant us access to your Third-
Party Account, without breach by you of any of the terms and conditions that govern your use of
the applicable Third-Party Account, and without obligating us to pay any fees or making us
subject to any usage limitations imposed by the third-party service provider of the Third-Party
Account. By granting us access to any Third-Party Accounts, you understand that (1) we may
access, make available, and store (if applicable) any content that you have provided to and
stored in your Third-Party Account (the “Social Network Content”) so that it is available on and
through the App via your account, including without limitation any friend lists and (2) we may
submit to and receive from your Third-Party Account additional information to the extent you are
notified when you link your account with the Third-Party Account. Depending on the Third-Party
Accounts you choose and subject to the privacy settings that you have set in such Third-Party
Accounts, personally identifiable information that you post to your Third-Party Accounts may be
available on and through your account on the App. Please note that if a Third-Party Account or
associated service becomes unavailable or our access to such Third Party Account is
terminated by the third-party service provider, then Social Network Content may no longer be
available on and through the App . You will have the ability to disable the connection between
your account on the Appand your Third-Party Accounts at any time. PLEASE NOTE THAT
YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED
WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR
AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS. We make no effort to
review any Social Network Content for any purpose, including but not limited to, for accuracy,
legality, or non-infringement, and we are not responsible for any Social Network Content. You
acknowledge and agree that we may access your email address book associated with a Third-
Party Account *. You can deactivate the connection between the App and your Third-Party
Account by contacting us using the contact information below or through your account settings
(if applicable). We will attempt to delete any information stored on our servers that was obtained
through such Third-Party Account, except the username and profile picture that become
associated with your account.
        </p>


                <h2 style={{marginTop:"30px"}}>SUBMISSIONS</h2>

        <p style={{marginTop:"20px"}}>
You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or
other information regarding the App (&quot;Submissions&quot;) provided by you to us are non-confidential
and shall become our sole property. We shall own exclusive rights, including all intellectual
property rights, and shall be entitled to the unrestricted use and dissemination of these
Submissions for any lawful purpose, commercial or otherwise, without acknowledgment or
compensation to you. You hereby waive all moral rights to any such Submissions, and you
hereby warrant that any such Submissions are original with you or that you have the right to
submit such Submissions. You agree there shall be no recourse against us for any alleged or
actual infringement or misappropriation of any proprietary right in your Submissions.
        </p>


                        <h2 style={{marginTop:"30px"}}>THIRD-PARTY WEBSITE AND CONTENT</h2>

        <p style={{marginTop:"20px"}}>
The App may contain (or you may be sent via the the App) links to other websites (&quot;Third-Party
Websites&quot;) as well as articles, photographs, text, graphics, pictures, designs, music, sound,
video, information, applications, software, and other content or items belonging to or originating
from third parties (&quot;Third-Party Content&quot;). Such Third-Party Websites and Third-Party Content
are not investigated, monitored, or checked for accuracy, appropriateness, or completeness by
us, and we are not responsible for any Third-Party Websites accessed through the App or any
Third-Party Content posted on, available through, or installed from the App, including the
content, accuracy, offensiveness, opinions, reliability, privacy practices, or other policies of or
contained in the Third-Party Websites or the Third-Party Content. Inclusion of, linking to, or
permitting the use or installation of any Third-Party Websites or any Third-Party Content does
not imply approval or endorsement thereof by us. If you decide to leave the App and access the
Third-Party Websites or to use or install any Third-Party Content, you do so at your own risk,
and you should be aware these Terms of Use no longer govern. You should review the
applicable terms and policies, including privacy and data gathering practices, of any website to
which you navigate from the App or relating to any applications you use or install from the App.
Any purchases you make through Third-Party Websites will be through other websites and from
other companies, and we take no responsibility whatsoever in relation to such purchases which
are exclusively between you and the applicable third party. You agree and acknowledge that we

do not endorse the products or services offered on Third-Party Websites and you shall hold us
harmless from any harm caused by your purchase of such products or services. Additionally,
you shall hold us harmless from any losses sustained by you or harm caused to you relating to
or resulting in any way from any Third-Party Content or any contact with Third-Party Websites.
        </p>



                        <h2 style={{marginTop:"30px"}}>APP MANAGEMENT</h2>

        <p style={{marginTop:"20px"}}>
We reserve the right, but not the obligation, to: (1) monitor the App for violations of these Terms
of Use; (2) take appropriate legal action against anyone who, in our sole discretion, violates the
law or these Terms of Use, including without limitation, reporting such user to law enforcement
authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the
availability of, or disable (to the extent technologically feasible) any of your Contributions or any
portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from
the App or otherwise disable all files and content that are excessive in size or are in any way
burdensome to our systems; and (5) otherwise manage the App in a manner designed to
protect our rights and property and to facilitate the proper functioning of the App.
        </p>

                                <h2 style={{marginTop:"30px"}}>PRIVACY POLICY</h2>

        <p style={{marginTop:"20px"}}>
We care about data privacy and security. Please review our Privacy Policy on (ADD LINK FOR
THE PRIVACY POLICY) . By using the App, you agree to be bound by our Privacy Policy, which is
incorporated into these Terms of Use. Please be advised the App is hosted in Bahrain and UAE.
If you access the App from any other region of the world with laws or other requirements
governing personal data collection, use, or disclosure that differ from applicable laws in UAE ,
then through your continued use of the APP, you are transferring your data to the UAE , and you
agree to have your data transferred to and processed in UAE .
        </p>


                                <h2 style={{marginTop:"30px"}}>TERM AND TERMINATION</h2>

        <p style={{marginTop:"20px"}}>
These Terms of Use shall remain in full force and effect while you use the App. WITHOUT
LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE
RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY
ACCESS TO AND USE OF THE APP (INCLUDING BLOCKING CERTAIN IP
ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY
APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
PARTICIPATION IN THE APP OR DELETE YOUR ACCOUNT AND ANY CONTENT OR
INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
DISCRETION.
If we terminate or suspend your account for any reason, you are prohibited from registering and
creating a new account under your name, a fake or borrowed name, or the name of any third
party, even if you may be acting on behalf of the third party. In addition to terminating or
suspending your account, we reserve the right to take appropriate legal action, including without
limitation pursuing civil, criminal, and injunctive redress.
        </p>

                                        <h2 style={{marginTop:"30px"}}>MODIFICATIONS AND INTERRUPTIONS</h2>

        <p style={{marginTop:"20px"}}>
We reserve the right to change, modify, or remove the contents of the App at any time or for any
reason at our sole discretion without notice. However, we have no obligation to update any
information on our App. We also reserve the right to modify or discontinue all or part of the App
without notice at any time. We will not be liable to you or any third party for any modification,
price change, suspension, or discontinuance of the App.
We cannot guarantee the App will be available at all times. We may experience hardware,
software, or other problems or need to perform maintenance related to the App, resulting in
interruptions, delays, or errors. We reserve the right to change, revise, update, suspend,
discontinue, or otherwise modify the App at any time or for any reason without notice to you.
You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused
by your inability to access or use the App during any downtime or discontinuance of the App.
Nothing in these Terms of Use will be construed to obligate us to maintain and support the App
or to supply any corrections, updates, or releases in connection therewith.
        </p>


                                        <h2 style={{marginTop:"30px"}}>GOVERNING LAW</h2>

        <p style={{marginTop:"20px"}}>
These Terms shall be governed by and defined following the laws of UAE .
T7D Gaming and yourself irrevocably consent that the courts of UAE shall have exclusive
jurisdiction to resolve any dispute which may arise in connection with these terms.
        </p>

                                        <h2 style={{marginTop:"30px"}}>DISPUTE RESOLUTION</h2>

        <p style={{marginTop:"20px"}}>
You agree to irrevocably submit all disputes related to Terms or the relationship established by
this Agreement to the jurisdiction of UAE courts. T7D Gaming shall also maintain the right to bring
proceedings as to the substance of the matter in the courts of the country where you reside or, if
these Terms are entered into in the course of your trade or profession, the state of your principal
place of business.
        </p>


                                                <h2 style={{marginTop:"30px"}}>CORRECTIONS</h2>

        <p style={{marginTop:"20px"}}>

There may be information on the App that contains typographical errors, inaccuracies, or
omissions, including descriptions, pricing, availability, and various other information. We reserve
the right to correct any errors, inaccuracies, or omissions and to change or update the
information on the App at any time, without prior notice.
        </p>


                                                <h2 style={{marginTop:"30px"}}>DISCLAIMER</h2>

        <p style={{marginTop:"20px"}}>
THE APP IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT
YOUR USE OF THE APP AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE
FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR
IMPLIED, IN CONNECTION WITH THE APP AND YOUR USE THEREOF, INCLUDING,
WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES
OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE APP’S
CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE APP AND WE WILL
ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND
USE OF THE APP, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE
SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
TRANSMISSION TO OR FROM APP, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE
LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE APP BY ANY THIRD PARTY,
AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR
ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE APP.
WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR
ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH
THE APP, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY
TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN
YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE
PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY

ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION
WHERE APPROPRIATE.
        </p>


       <h2 style={{marginTop:"30px"}}>LIMITATIONS OF LIABILITY</h2>

        <p style={{marginTop:"20px"}}>
IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO
YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
USE OF THE APP, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE
FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY,
BY YOU TO US DURING THE THREE (3) MONTH PERIOD PRIOR TO ANY CAUSE OF
ACTION ARISING. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT
ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF
CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE
ADDITIONAL RIGHTS.
        </p>
        
       <h2 style={{marginTop:"30px"}}>INDEMNIFICATION</h2>

        <p style={{marginTop:"20px"}}>
You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and
all of our respective officers, agents, partners, and employees, from and against any loss,
damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made
by any third party due to or arising out of: (1) your Contributions; (2) use of the App; (3) breach
of these Terms of Use; (4) any breach of your representations and warranties set forth in these
Terms of Use; (5) your violation of the rights of a third party, including but not limited to
intellectual property rights; or (6) any overt harmful act toward any other user of the App with
whom you connected via the App. Notwithstanding the foregoing, we reserve the right, at your
expense, to assume the exclusive defense and control of any matter for which you are required
to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims.

We will use reasonable efforts to notify you of any such claim, action, or proceeding which is
subject to this indemnification upon becoming aware of it.
        </p>
        



       <h2 style={{marginTop:"30px"}}>USER DATA</h2>

        <p style={{marginTop:"20px"}}>
We will maintain certain data that you transmit to the App for the purpose of managing the
performance of the App, as well as data relating to your use of the App. Although we perform
regular routine backups of data, you are solely responsible for all data that you transmit or that
relates to any activity you have undertaken using the App. You agree that we shall have no
liability to you for any loss or corruption of any such data, and you hereby waive any right of
action against us arising from any such loss or corruption of such data.
        </p>

               <h2 style={{marginTop:"30px"}}>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND
SIGNATURES</h2>

        <p style={{marginTop:"20px"}}>
Visiting the App, sending us emails, and completing online forms constitute electronic
communications. You consent to receive electronic communications, and you agree that all
agreements, notices, disclosures, and other communications we provide to you electronically,
via email and on the App, satisfy any legal requirement that such communication be in writing.
YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS,
ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES,
POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR
VIA THE APP. You hereby waive any rights or requirements under any statutes, regulations,
rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery
or retention of non-electronic records, or to payments or the granting of credits by any means
other than electronic means.
        </p>


               <h2 style={{marginTop:"30px"}}>MISCELLANEOUS</h2>

        <p style={{marginTop:"20px"}}>
These Terms of Use and any policies or operating rules posted by us on the App or in respect to
the App constitute the entire agreement and understanding between you and us. Our failure to

exercise or enforce any right or provision of these Terms of Use shall not operate as a waiver of
such right or provision. These Terms of Use operate to the fullest extent permissible by law. We
may assign any or all of our rights and obligations to others at any time. We shall not be
responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond
our reasonable control. If any provision or part of a provision of these Terms of Use is
determined to be unlawful, void, or unenforceable, that provision or part of the provision is
deemed severable from these Terms of Use and does not affect the validity and enforceability of
any remaining provisions. There is no joint venture, partnership, employment or agency
relationship created between you and us as a result of these Terms of Use or use of the App.
You agree that these Terms of Use will not be construed against us by virtue of having drafted
them. You hereby waive any and all defenses you may have based on the electronic form of
these Terms of Use and the lack of signing by the parties hereto to execute these Terms of Use.
        </p>

        <h2 style={{ marginTop: "30px" }}>CONTACT US</h2>
        
        <ul>
          <li>In order to resolve a complaint regarding the App or to receive further information regarding use
of the App, please contact us at:</li>
          <li>T7D GAMING L.L.C</li>
          <li>3901-045 Czar Business Center</li>
          <li>The One Tower , Dubai UAE</li>
          <li>Phone: +216 52 472 514</li>
          <li>Contact@t7dgaming.com</li>
        </ul>

      </TextContainer>
    </Container>
  )
}

export default Index