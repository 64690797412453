import React from 'react'
import styled from 'styled-components'


import discord from '../../icons/discord.svg'
import instagram from '../../icons/instagram.svg'
import Youtube from '../../icons/Youtube.svg'
import whatsapp from '../../icons/whatsapp.svg'
import facebook from '../../icons/facebook.svg'
import { useNavigate  } from "react-router-dom";
import visamaster from './visa-and-mastercard.png'
const Container = styled.div`
height: 50px;
position: absolute;
bottom: 0;
width: 100%;
left: 0;
display: flex;
flex-wrap: nowrap;
@media screen and (max-width: 500px) {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 200px;
}
`
const Icons = styled.div`
width: 50%;
gap: 20px;
display: flex;
align-items: center;
justify-content: flex-start;
padding-left: 10%;
@media screen and (max-width: 500px) {
    display: flex;
        align-items: center;
        justify-content: center;
    height: 50px !important;
    width: 100%;
    padding-left: 0%;

}
`
const Textes= styled.div`
width: 50%;
gap: 20px;
display: flex;
align-items: center;
justify-content: center;
    p{
        color: #9ca2cf;
        display: inline-block;
        font-size: 1rem;
        cursor: pointer;
    }
    @media screen and (max-width: 500px) {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 150px !important;
        width: 100%;
        flex-direction: column;
}
`

const OneIcon = styled.div`
    cursor: pointer;
`

const Bottom = () => {

    const navigate = useNavigate();
    const goTo = (route) => {
            navigate(route)
    }
  return (
    <Container>
        <Icons>
            <OneIcon><a href="https://discord.gg/6QSAm8uxQf"><img alt="discord" src={discord}/></a></OneIcon>
            <OneIcon><a href="https://www.instagram.com/T7Dgaming/"><img alt="instagram" src={instagram}/></a></OneIcon>
            <OneIcon><a href="https://www.youtube.com/channel/UChGT8HD_un0-cetCXh32tYQ"><img alt="youtube" src={Youtube}/></a></OneIcon>
            <OneIcon><a href="https://api.whatsapp.com/send/?phone=21654790406&app_absent=0"><img src={whatsapp}/></a></OneIcon>
            <OneIcon><a href="https://www.facebook.com/T7Dgaming"><img alt="facebook" src={facebook}/></a></OneIcon>
            <OneIcon><img style={{maxHeight:"30px"}} src={visamaster}/></OneIcon>
        </Icons>

        <Textes>
            <p onClick={() => goTo('Termsandconditions')}>Terms and conditions</p>
            <p onClick={() => goTo('Privacy')}>Privacy and policy</p>
            <p>Copyright © 2022 T7D gaming. All rights reserved.</p>
        </Textes>
    </Container>
  )
}

export default Bottom