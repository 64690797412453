import React from 'react'
import styled from 'styled-components';
import b5 from '../../images/b5.png'
import Header from './Header.js'
import Socieal from './Socieal.js'
import FormAndMap from './FormAndMap.js'
import Bottom from './Bottom.js'
import { ToastContainer, toast } from 'react-toastify';

const Container = styled.div`
height: 100vh;
width: 100vw;
position: relative;
left: 0;
top: 0;
//background-image: url(${b5});
background-size: cover;
min-height:800px;

@media screen and (max-width: 1200px) {
height: auto;
}
@media screen and (max-width: 1200px) {
  padding-top: 40px;
}
`
//left: calc(var(--navBarWidth));
const Wrapper= styled.div`
position: relative;
width: calc(100vw - var(--navBarWidth));
left: calc(var(--navBarWidth));
height: 100%;
@media screen and (max-width: 1200px) {
  width: 100%;
left:0px;
}
`
const index = () => {

  const notify = () => toast.info('Message was sent', {
  position: toast.POSITION.BOTTOM_CENTER
});
const startnotify=()=>{
  notify()
}


  return (
    <Container id="Contact">
            <ToastContainer   style={{fontSize:"2rem"}}/>

      <Wrapper>

        <Header></Header>
        <Socieal></Socieal>
        <FormAndMap startnotify={startnotify}></FormAndMap>
        <Bottom></Bottom>
        </Wrapper>

    </Container>
  )
}

export default index